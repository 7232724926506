import {OrderItemModel} from '../../../../../../core/ek-e-commerce/ek-models/OrderItem.model';
import {FolderModel} from '../../../../../../core/ek-e-commerce/ek-models/folder.model';
import {StocksService} from '../../../../../../core/ek-e-commerce/ek-services/stocks.service';
import {CreditModel} from "../../../../../../core/ek-e-commerce/ek-models/credit.model";
import {OrderSplitService} from "../../../../../../core/ek-e-commerce/ek-services/order-split.service";
import {ClientsService} from "../../../../../../core/ek-e-commerce/ek-services/clients.service";
import {OrderSplit} from "../../../../../../core/ek-e-commerce/ek-models/orderSplit";
import {ClientModel} from "../../../../../../core/ek-e-commerce/ek-models/client.model";
import {ocrInfo} from "../../../../../../core/ek-e-commerce/ek-models/document-ocr-information";
import {Component, Input, OnInit} from "@angular/core";
import {DocumentModel} from "../../../../../../core/ek-e-commerce/ek-models/document.model";
import {DocumentService} from "../../../../../../core/services/document.service";
import {PDFDocument, rgb, StandardFonts} from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
// import { AccountService } from 'src/app/Services/account.service';
import {ActivatedRoute} from "@angular/router";
import {BehaviorSubject} from "rxjs";
import {DatePipe} from "@angular/common";
import {ProfessionTranslatePipe} from "../../../../../../core/_base/layout/pipes/profession-translate.pipe";
import {EkProductsService} from "../../../../../../core/ek-e-commerce/ek-services/ek-product.service";
import {EkCategoriesService} from "../../../../../../core/ek-e-commerce/ek-services/ek-categories.service";
import {StockModel} from "../../../../../../core/ek-e-commerce/ek-models/stock.model";

@Component({
    selector: "kt-fourth-step",
    templateUrl: "./fourth-step.component.html",
    styleUrls: ["./fourth-step.component.scss"],
    providers: [DatePipe, ProfessionTranslatePipe],
})
export class FourthStepComponent implements OnInit {
    @Input() bankDocuments!: DocumentModel[];
    demandeOuvertureCompteB!: string;
    @Input() information!: ocrInfo;
    @Input() client!: ClientModel;
    @Input() order!: OrderSplit;
    @Input() folder!: FolderModel;

    sitProf: string = "";
    sitPerso: string = "";
    autorisationDePrelevement: any;
    // autorisationDePrelevementTwo: any;
    demandeDeFinancement: any;
    ficheDeSignature: any;
    engagementDeSolde: any;
    fatca: any;
    autorisationDeConsultation: any;
    folderId!: number;
    orderId!: number;
    image: any;
    credit: CreditModel;
    stocks: StockModel[];

    bankDocuments$ = new BehaviorSubject<DocumentModel[]>(null);

    downLoadFiles$ = new BehaviorSubject<boolean>(false);
    readonly loading$ = this.downLoadFiles$.asObservable();

    currentRole: string = "";
    categoriess: string[] = [];
    datePrelevement: string = "";

    async createPdf() {

        switch (this.information?.personnelInformation?.socialClientCategory) {
            case 'DIVORCE' :
                this.sitPerso = 'Divorcé(e)';
                break;
            case 'MARRIED' :
                this.sitPerso = 'Marrié(e)';
                break;
            case 'SINGLE' :
                this.sitPerso = 'Célibataire';
                break;
            case 'WIDOWER' :
                this.sitPerso = 'Veuf(ve)';
                break;
            default :
                '';
                break;
        }

        // Loading Arabic Font
        const url =
            "https://ekiclik-docs.s3.eu-west-2.amazonaws.com/NotoSansArabic-Black.ttf";
        const fontBytes = await fetch(url).then((res) => res.arrayBuffer());

        // Demande ouverture de compte --------------------------------------------------
        const demandeOuvertureCompteB = await fetch(
            this.demandeOuvertureCompteB
        ).then((res) => res.arrayBuffer());

        const DOCB = await PDFDocument.load(demandeOuvertureCompteB);
        DOCB.registerFontkit(fontkit);
        const arabicFont = await DOCB.embedFont(fontBytes);
        const helveticaFont = await DOCB.embedFont(StandardFonts.HelveticaBold);

        const DOCBpages = DOCB.getPages();
        const DOCBfirstPage = DOCBpages[0];
        const DOCBSecondPage = DOCBpages[1];

        DOCBfirstPage.drawText(
            `${this.folder?.requestNumber ? this.folder?.requestNumber : ''}`,
            {
                x: 40,
                y: 745,
                size: 15,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            }
        );

        DOCBSecondPage.drawText(
            `${this.folder?.requestNumber ? this.folder?.requestNumber : ''}`,
            {
                x: 40,
                y: 760,
                size: 15,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            }
        );

        DOCBfirstPage.drawText(
            `${
                this.information?.personnelInformation?.firstName
                    ? this.information?.personnelInformation?.firstName!
                    : ""
            }   ${
                this.information?.personnelInformation?.lastName
                    ? this.information?.personnelInformation?.lastName
                    : ""
            }`,
            {
                x: 160,
                y: 615,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            }
        );

        DOCBfirstPage.drawText(
            `${
                this.information?.personnelInformation?.firstNameFather
                    ? this.information?.personnelInformation?.firstNameFather!
                    : ""
            } et ${
                this.information?.personnelInformation?.lastNameMother
                    ? this.information?.personnelInformation?.lastNameMother
                    : ""
            } ${
                this.information?.personnelInformation?.firstNameMother
                    ? this.information?.personnelInformation?.firstNameMother
                    : ""
            }`,
            {
                x: 160,
                y: 598,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            }
        );

        DOCBfirstPage.drawText(
            `${
                this.information?.personnelInformation?.fistNameEnArab
                    ? this.information?.personnelInformation?.fistNameEnArab!
                    : ""
            }   ${
                this.information?.personnelInformation?.lastNameEnArab
                    ? this.information?.personnelInformation?.lastNameEnArab
                    : ""
            }`,
            {
                x: this.getX(
                    540,
                    this.information?.personnelInformation?.lastNameEnArab! +
                    this.information?.personnelInformation?.fistNameEnArab!,
                    arabicFont,
                    12
                ),
                y: 635,
                size: 10,
                font: arabicFont,
                color: rgb(0, 0, 0),
            }
        );

        DOCBfirstPage.drawText(
            `${
                this.information?.personnelInformation?.fistNameEnArab
                    ? this.information?.personnelInformation?.fistNameEnArab!
                    : ""
            }   ${
                this.information?.personnelInformation?.lastNameEnArab
                    ? this.information?.personnelInformation?.lastNameEnArab
                    : ""
            }`,
            {
                x: this.getX(
                    530,
                    this.information?.personnelInformation?.lastNameEnArab! +
                    this.information?.personnelInformation?.fistNameEnArab!,
                    arabicFont,
                    12
                ),
                y: 615,
                size: 10,
                font: arabicFont,
                color: rgb(0, 0, 0),
            }
        );

        DOCBfirstPage.drawText(
            `${
                this.information?.personnelInformation?.placeOfBrith
                    ? this.information?.personnelInformation?.placeOfBrith!
                    : ""
            }   ${
                this.information?.personnelInformation?.birthDay
                    ? this.information?.personnelInformation?.birthDay
                    : ""
            }`,
            {
                x: 160,
                y: 580,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            }
        );

        switch (this.information?.personnelInformation?.socialClientCategory) {
            case 'DIVORCE' :
                this.sitPerso = 'Divorcé(e)';
                break;
            case 'MARRIED' :
                this.sitPerso = 'Marrié(e)';
                break;
            case 'SINGLE' :
                this.sitPerso = 'Célibataire';
                break;
            case 'WIDOWER' :
                this.sitPerso = 'Veuf(ve)';
                break;
            default :
                '';
                break;
        }

        DOCBfirstPage.drawText(`${this.sitPerso ? this.sitPerso : ""}`, {
            x: 160,
            y: 560,
            size: 10,
            font: helveticaFont,
            color: rgb(0, 0, 0),
        });

        DOCBfirstPage.drawText(
            `${
                this.information?.personnelSituation?.dependentChild
                    ? this.information?.personnelSituation?.dependentChild
                    : 0
            }`,
            {
                x: 160,
                y: 540,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            }
        );
        DOCBfirstPage.drawText(
            `${
                this.information?.professionalSituation?.profession
                    ? this.professionTranslatePipe.transform(this.information?.professionalSituation?.profession)
                    : ""
            }`,
            {
                // x: this.getXFr(20,this.information?.professionalSituation?.profession! , helveticaFont, 11),
                x: 180,
                y: 520,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            }
        );

        DOCBfirstPage.drawText(
            `${
                this.information?.professionalSituation?.employer
                    ? this.information?.professionalSituation?.employer
                    : ""
            }     / `,
            {
                // x: this.getXFr(40 ,this.information?.professionalSituation?.employer! , helveticaFont, 11),
                x: 140,
                y: 500,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            }
        );
        DOCBfirstPage.drawText(
            `${
                this.information?.professionalSituation?.employerAddress
                    ? this.information?.professionalSituation?.employerAddress
                    : ""
            }`,
            {
                // x: this.getXFr(40 ,this.information?.professionalSituation?.employerAddress! , helveticaFont, 11),
                x: 250,
                y: 500,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            }
        );

        DOCBfirstPage.drawText(
            `${
                this.information?.personnelSituation?.personnelSituationAddress
                    ? this.invertNumbers(this.information?.personnelSituation?.personnelSituationAddress!)
                    : ""
            }`,
            {
                x: this.getX(
                    550,
                    this.invertNumbers(this.information?.personnelSituation?.personnelSituationAddress!)!,
                    arabicFont,
                    12
                ),
                y: 457,
                size: 12,
                font: arabicFont,
                color: rgb(0, 0, 0),
            }
        );

        DOCBfirstPage.drawText(
            `${
                this.information?.personnelSituation?.personnelSituationAddressAscii
                    ? this.information?.personnelSituation?.personnelSituationAddressAscii
                    : ""
            }`,
            {
                x: 80,
                y: 462,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            }
        );
        DOCBfirstPage.drawText(`${this.order?.phone ? this.order?.phone : ""}`, {
            x: 60,
            y: 313,
            size: 9,
            font: helveticaFont,
            color: rgb(0, 0, 0),
        });
        DOCBfirstPage.drawText(`${this.client?.email ? this.client?.email : ""}`, {
            x: 70,
            y: 295,
            size: 9,
            font: helveticaFont,
            color: rgb(0, 0, 0),
        });
        DOCBfirstPage.drawText(
            `${this.order?.salarySimulation ? this.order?.salarySimulation : ""} DZD`,
            {
                x: 130,
                y: 283,
                size: 9,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            }
        );

        DOCBSecondPage.drawText(
            `${this.order.pointOfSaleTo?.posCommune ? this.order.pointOfSaleTo?.posCommune : ''}`,
            {
                x: 150,
                y: 472.5,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            }
        );

        DOCBSecondPage.drawText(
            `${this.order.createdAt ? this.datepipe.transform(new Date(this.order.createdAt), "yyyy/MM/dd") : ''}`,
            {
                x: 230,
                y: 472.5,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            }
        );

        //Autorisation de prelevement
        const autorisationDePrelevement = await fetch(
            this.autorisationDePrelevement
        ).then((res) => res.arrayBuffer());

        // Autorisation de prélèvement
        const ADP = await PDFDocument.load(autorisationDePrelevement);
        ADP.registerFontkit(fontkit);
        const arabicFont2 = await ADP.embedFont(fontBytes);
        const helveticaFont2 = await ADP.embedFont(StandardFonts.HelveticaBold);

        const ADPpages = ADP.getPages();
        const ADPfirstPage = ADPpages[0];

        ADPfirstPage.drawText(
            `${this.folder?.requestNumber ? this.folder?.requestNumber : ''}`,
            {
                x: 250,
                y: 577.5,
                size: 12,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            }
        );

        var x = 115;
        if (this.information?.personnelInformation?.firstName) {
            for (
                var i = 0;
                i < this.information?.personnelInformation?.firstName!.length;
                i++
            ) {
                ADPfirstPage.drawText(
                    `${this.information?.personnelInformation?.firstName!.charAt(i)}`,
                    {
                        x: x,
                        y: 425,
                        size: 18,
                        font: helveticaFont2,
                        color: rgb(0, 0, 0),
                    }
                );
                x = x + 21;
            }
        }
        x = 115;
        if (this.information?.personnelInformation?.lastName) {
            for (
                var i = 0;
                i < this.information?.personnelInformation?.lastName!.length;
                i++
            ) {
                ADPfirstPage.drawText(
                    `${this.information?.personnelInformation?.lastName!.charAt(i)}`,
                    {
                        x: x,
                        y: 460,
                        size: 18,
                        font: helveticaFont2,
                        color: rgb(0, 0, 0),
                    }
                );
                x = x + 21;
            }
        }
        x = 115;
        if (this.information?.financialSituation?.accountNumber) {
            for (
                var i = 0;
                i < String(this.information?.financialSituation?.accountNumber).length;
                i++
            ) {
                ADPfirstPage.drawText(
                    `${String(this.information?.financialSituation?.accountNumber).charAt(
                        i
                    )}`,
                    {
                        x: x,
                        y: 390,
                        size: 18,
                        font: helveticaFont2,
                        color: rgb(0, 0, 0),
                    }
                );
                x = x + 21;
            }
        }

        let bankAccountNumber = '391779'
        let bankAccountKey = '19'
        x = 115;

        if (bankAccountNumber) {
            for (
                var i = 0;
                i < bankAccountNumber.length;
                i++
            ) {
                ADPfirstPage.drawText(
                    `${bankAccountNumber.charAt(
                        i
                    )}`,
                    {
                        x: x,
                        y: 352,
                        size: 18,
                        font: helveticaFont2,
                        color: rgb(0, 0, 0),
                    }
                );
                x = x + 21;
            }
        }
        x = 335;
        if (this.information?.financialSituation?.accountKey) {
            for (
                var i = 0;
                i < String(this.information?.financialSituation?.accountKey!).length;
                i++
            ) {
                ADPfirstPage.drawText(
                    `${String(this.information?.financialSituation?.accountKey)!.charAt(
                        i
                    )}`,
                    {
                        x: x,
                        y: 390,
                        size: 18,
                        font: helveticaFont2,
                        color: rgb(0, 0, 0),
                    }
                );
                x = x + 21;
            }
        }
        x = 335;
        if (bankAccountKey) {
            for (
                var i = 0;
                i < bankAccountKey.length;
                i++
            ) {
                ADPfirstPage.drawText(
                    `${bankAccountKey.charAt(
                        i
                    )}`,
                    {
                        x: x,
                        y: 352,
                        size: 18,
                        font: helveticaFont2,
                        color: rgb(0, 0, 0),
                    }
                );
                x = x + 21;
            }
        }

        x = 115;
        if (this.datePrelevement ? this.datePrelevement : '') {
            for (
                var i = 0;
                i < this.datePrelevement.length;
                i++
            ) {
                ADPfirstPage.drawText(
                    `${this.datePrelevement!.charAt(i)}`,
                    {
                        x: x,
                        y: 278.7,
                        size: 18,
                        font: helveticaFont2,
                        color: rgb(0, 0, 0),
                    }
                );
                x = x + 21;
            }
        }

        x = 115;
        if (this.credit?.monthlyPayment) {
            const monthlyPayment: number = this.credit.monthlyPayment + 100
            for (
                var i = 0;
                i < String(this.formatNumber(monthlyPayment)).length;
                i++
            ) {
                ADPfirstPage.drawText(
                    `${String(this.formatNumber(monthlyPayment))!.charAt(i)}`,
                    {
                        x: x,
                        y: 246.5,
                        size: 18,
                        font: helveticaFont2,
                        color: rgb(0, 0, 0),
                    }
                );
                x = x + 21;
            }
        }

        x = 115;
        if (this.credit?.numberOfMonths) {
            const numMonths: string = String(this.credit.numberOfMonths - 1)
            for (
                var i = 0;
                i < numMonths.length;
                i++
            ) {
                ADPfirstPage.drawText(
                    `${numMonths!.charAt(i)}`,
                    {
                        x: x,
                        y: 212,
                        size: 18,
                        font: helveticaFont2,
                        color: rgb(0, 0, 0),
                    }
                );
                x = x + 21;
            }
        }

        x = 520;
        if (this.information?.personnelInformation?.firstName) {
            for (
                var i = 0;
                i < this.information?.personnelInformation?.firstName!.length;
                i++
            ) {
                ADPfirstPage.drawText(
                    `${this.information?.personnelInformation?.firstName!.charAt(i)}`,
                    {
                        x: x,
                        y: 457,
                        size: 18,
                        font: helveticaFont2,
                        color: rgb(0, 0, 0),
                    }
                );
                x = x + 21;
            }
        }
        x = 520;
        if (this.information?.personnelInformation?.lastName) {
            for (
                var i = 0;
                i < this.information?.personnelInformation?.lastName!.length;
                i++
            ) {
                ADPfirstPage.drawText(
                    `${this.information?.personnelInformation?.lastName!.charAt(i)}`,
                    {
                        x: x,
                        y: 492,
                        size: 18,
                        font: helveticaFont2,
                        color: rgb(0, 0, 0),
                    }
                );
                x = x + 21;
            }
        }
        x = 520;
        if (this.information?.financialSituation?.accountNumber) {
            for (
                var i = 0;
                i < String(this.information?.financialSituation?.accountNumber!).length;
                i++
            ) {
                ADPfirstPage.drawText(
                    `${String(this.information?.financialSituation?.accountNumber)!.charAt(
                        i
                    )}`,
                    {
                        x: x,
                        y: 422,
                        size: 18,
                        font: helveticaFont2,
                        color: rgb(0, 0, 0),
                    }
                );
                x = x + 21;
            }
        }
        x = 520;
        if (bankAccountNumber) {
            for (
                var i = 0;
                i < bankAccountNumber.length;
                i++
            ) {
                ADPfirstPage.drawText(
                    `${bankAccountNumber.charAt(
                        i
                    )}`,
                    {
                        x: x,
                        y: 383,
                        size: 18,
                        font: helveticaFont2,
                        color: rgb(0, 0, 0),
                    }
                );
                x = x + 21;
            }
        }
        x = 740;
        if (this.information?.financialSituation?.accountKey) {
            for (
                var i = 0;
                i < String(this.information?.financialSituation?.accountKey!).length;
                i++
            ) {
                ADPfirstPage.drawText(
                    `${String(this.information?.financialSituation?.accountKey)!.charAt(
                        i
                    )}`,
                    {
                        x: x,
                        y: 422,
                        size: 18,
                        font: helveticaFont2,
                        color: rgb(0, 0, 0),
                    }
                );
                x = x + 21;
            }
        }
        x = 740;
        if (bankAccountKey) {
            for (
                var i = 0;
                i < bankAccountKey.length;
                i++
            ) {
                ADPfirstPage.drawText(
                    `${bankAccountKey.charAt(
                        i
                    )}`,
                    {
                        x: x,
                        y: 383,
                        size: 18,
                        font: helveticaFont2,
                        color: rgb(0, 0, 0),
                    }
                );
                x = x + 21;
            }
        }

        x = 520;
        if (this.datePrelevement ? this.datePrelevement : '') {
            for (
                var i = 0;
                i < this.datePrelevement.length;
                i++
            ) {
                ADPfirstPage.drawText(
                    `${this.datePrelevement!.charAt(i)}`,
                    {
                        x: x,
                        y: 310.5,
                        size: 18,
                        font: helveticaFont2,
                        color: rgb(0, 0, 0),
                    }
                );
                x = x + 21;
            }
        }

        x = 520;
        if (this.credit?.monthlyPayment) {
            const monthlyPayment: number = this.credit.monthlyPayment + 100
            for (
                var i = 0;
                i < String(this.formatNumber(monthlyPayment)).length;
                i++
            ) {
                ADPfirstPage.drawText(
                    `${String(this.formatNumber(monthlyPayment))!.charAt(i)}`,
                    {
                        x: x,
                        y: 277.3,
                        size: 18,
                        font: helveticaFont2,
                        color: rgb(0, 0, 0),
                    }
                );
                x = x + 21;
            }
        }

        x = 520;
        if (this.credit?.numberOfMonths) {
            const numMonths: string = String(this.credit.numberOfMonths - 1)
            for (
                var i = 0;
                i < numMonths.length;
                i++
            ) {
                ADPfirstPage.drawText(
                    `${numMonths!.charAt(i)}`,
                    {
                        x: x,
                        y: 244.5,
                        size: 18,
                        font: helveticaFont2,
                        color: rgb(0, 0, 0),
                    }
                );
                x = x + 21;
            }
        }

        ADPfirstPage.drawText(
            `${this.order.pointOfSaleTo?.posCommune ? this.order.pointOfSaleTo?.posCommune : ''}`,
            {
                x: 300,
                y: 198,
                size: 10,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            }
        );

        ADPfirstPage.drawText(
            `${this.order.createdAt ? this.datepipe.transform(new Date(this.order.createdAt), "dd/MM/yyyy") : ''}`,
            {
                x: 355,
                y: 198,
                size: 10,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            }
        );

        ADPfirstPage.drawText(
            `${this.order.pointOfSaleTo?.posCommune ? this.order.pointOfSaleTo?.posCommune : ''}`,
            {
                x: 685,
                y: 92,
                size: 10,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            }
        );

        ADPfirstPage.drawText(
            `${this.order.createdAt ? this.datepipe.transform(new Date(this.order.createdAt), "dd/MM/yyyy") : ''}`,
            {
                x: 745,
                y: 92,
                size: 10,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            }
        );


        //Demande de financement
        const demandeDeFinancement = await fetch(this.demandeDeFinancement).then(
            (res) => res.arrayBuffer()
        );
        const DDF = await PDFDocument.load(demandeDeFinancement);
        DDF.registerFontkit(fontkit);
        const arabicFont3 = await DDF.embedFont(fontBytes);
        const helveticaFont3 = await DDF.embedFont(StandardFonts.HelveticaBold);

        const DDFpages = DDF.getPages();
        const DDFfirstPage = DDFpages[0];
        const DDFsecondPage = DDFpages[1];
        const DDFthirdPage = DDFpages[2];
        const DDFFourthPage = DDFpages[3];
        const DDFFifthPage = DDFpages[4];
        var x = 430;

        DDFfirstPage.drawText(
            `${this.folder?.requestNumber ? this.folder?.requestNumber : ''}`,
            {
                x: 35,
                y: 735,
                size: 15,
                font: helveticaFont3,
                color: rgb(0, 0, 0),
            }
        );

        DDFfirstPage.drawText(
            `${
                this.information?.personnelInformation?.lastNameEnArab
                    ? this.information?.personnelInformation?.lastNameEnArab
                    : ""
            }`,
            {
                x: this.getX(
                    x,
                    this.information?.personnelInformation?.lastNameEnArab!,
                    arabicFont3,
                    12
                ),
                y: 660,
                size: 12,
                font: arabicFont3,
                color: rgb(0, 0, 0),
            }
        );

        if (this.information?.personnelInformation?.socialClientCategory === 'MARRIED' || this.information?.personnelInformation?.socialClientCategory === 'WIDOWER') {
            DDFfirstPage.drawText(
                `${
                    this.information?.personnelInformation?.maidenNameInArabic
                        ? this.information?.personnelInformation?.maidenNameInArabic
                        : ""
                }`,
                {
                    x: this.getX(
                        x,
                        this.information?.personnelInformation?.maidenNameInArabic!,
                        arabicFont3,
                        12
                    ),
                    y: 635,
                    size: 12,
                    font: arabicFont3,
                    color: rgb(0, 0, 0),
                }
            );
        }

        DDFfirstPage.drawText(
            `${
                this.information?.personnelInformation?.fistNameEnArab
                    ? this.information?.personnelInformation?.fistNameEnArab
                    : ""
            }`,
            {
                x: this.getX(
                    x,
                    this.information?.personnelInformation?.fistNameEnArab!,
                    arabicFont3,
                    12
                ),
                y: 610,
                size: 12,
                font: arabicFont3,
                color: rgb(0, 0, 0),
            }
        );
        DDFfirstPage.drawText(
            `${
                // this.information?.personnelInformation?.birthDay!.length > 12
                //   ? this.datepipe.transform(
                //       this.information?.personnelInformation?.birthDay,
                //       'dd/MM/yyyy'
                //     )
                //   :
                this.information?.personnelInformation?.birthDay ? this.information?.personnelInformation?.birthDay : ''
            }`,
            {
                x: x - 50,
                y: 582,
                size: 12,
                font: helveticaFont3,
                color: rgb(0, 0, 0),
            }
        );
        var y;
        switch (this.information?.personnelInformation?.socialClientCategory) {
            case "SINGLE": {
                y = 560;
                break;
            }
            case "MARRIED": {
                y = 540;
                break;
            }
            case "DIVORCE": {
                y = 521.5;
                break;
            }
            case "WIDOWER": {
                y = 503;
            }
        }
        DDFfirstPage.drawText(`X`, {
            x: x - 28,
            y: y,
            size: 15,
            font: helveticaFont3,
            color: rgb(0, 0, 0),
        });

        if (this.information?.personnelSituation?.personnelSituationAddress) {
            const adress =
                this.invertNumbers(this.information?.personnelSituation?.personnelSituationAddress!)!.split(
                    /\s+/
                );
            var firstPart =
                this.numberTrim(adress[0]) +
                " " +
                this.numberTrim(adress[1]) +
                " " +
                this.numberTrim(adress[2]);
            var secondPart = String(adress.slice(3).join(" "));
            if (firstPart.length < 20) {
                firstPart =
                    this.numberTrim(adress[0]) +
                    " " +
                    this.numberTrim(adress[1]) +
                    " " +
                    this.numberTrim(adress[2]) +
                    " " +
                    this.numberTrim(adress[3]) +
                    " " +
                    this.numberTrim(adress[4]);
                secondPart = String(adress.slice(5).join(" "));
            }

            let firstPartArray = firstPart.split(" ")
            firstPart = firstPartArray.filter(word => word !== 'undefined').join(" ")
            let secondPartArray = secondPart.split(" ")
            secondPart = secondPartArray.filter(word => word !== 'undefined').join(" ")

            DDFfirstPage.drawText(`${firstPart}`, {
                x: this.getX(x + 20, firstPart, arabicFont3, 12),
                y: 480,
                size: 12,
                font: arabicFont3,
                color: rgb(0, 0, 0),
            });
            DDFfirstPage.drawText(`${secondPart}`, {
                x: this.getX(x + 20, secondPart, arabicFont3, 12),
                y: 460,
                size: 12,
                font: arabicFont3,
                color: rgb(0, 0, 0),
            });
        }

        DDFfirstPage.drawText(`${this.order?.phone ? this.order?.phone : ""}`, {
            x: x - 100,
            y: 375,
            size: 12,
            font: helveticaFont3,
            color: rgb(0, 0, 0),
        });

        DDFfirstPage.drawText(`${this.client.email ? this.client.email : ""}`, {
            x: this.getX(x + 20, this.client.email, helveticaFont3, 12),
            y: 343,
            size: 12,
            font: helveticaFont3,
            color: rgb(0, 0, 0),
        });

        DDFfirstPage.drawText(
            `${
                this.information?.personnelSituation?.dependentChild! +
                this.information?.personnelSituation?.dependentOtherPeople!
                    ? this.information?.personnelSituation?.dependentChild! +
                    this.information?.personnelSituation?.dependentOtherPeople!
                    : 0
            }`,
            {
                x: x - 55,
                y: 315,
                size: 12,
                font: arabicFont3,
                color: rgb(0, 0, 0),
            }
        );

        DDFfirstPage.drawText(
            `${
                this.information?.professionalSituation?.profession
                    ? this.professionTranslatePipe.transform(this.information?.professionalSituation?.profession)
                    : ""
            }`,
            {
                x: this.getX(
                    x + 10,
                    this.information?.professionalSituation?.profession!,
                    helveticaFont3,
                    11
                ),
                y: 285,
                size: 11,
                font: helveticaFont3,
                color: rgb(0, 0, 0),
            }
        );

        DDFfirstPage.drawText(
            `${
                this.information?.professionalSituation?.employer
                    ? this.information?.professionalSituation?.employer
                    : ""
            }`,
            {
                x: this.getX(
                    x + 10,
                    this.information?.professionalSituation?.employer!,
                    helveticaFont3,
                    11
                ),
                y: 260,
                size: 11,
                font: helveticaFont3,
                color: rgb(0, 0, 0),
            }
        );
        DDFfirstPage.drawText(
            `${
                this.information?.professionalSituation?.employerAddress
                    ? this.information?.professionalSituation?.employerAddress
                    : ""
            }`,
            {
                x: this.getX(
                    x + 10,
                    this.information?.professionalSituation?.employerAddress!,
                    helveticaFont3,
                    11
                ),
                y: 225,
                size: 11,
                font: helveticaFont3,
                color: rgb(0, 0, 0),
            }
        );

        DDFfirstPage.drawText(
            `${this.order?.salarySimulation ? this.addSeparation(Math.round(100 * this.order?.salarySimulation) / 100) : ""}`,
            {
                x: x - 55,
                y: 125,
                size: 12,
                font: helveticaFont3,
                color: rgb(0, 0, 0),
            }
        );

        DDFfirstPage.drawText(`دج`, {
            x: 320,
            y: 125,
            size: 11,
            font: arabicFont3,
            color: rgb(0, 0, 0),
        });

        DDFsecondPage.drawText(
            `${this.folder?.requestNumber ? this.folder?.requestNumber : ''}`,
            {
                x: 35,
                y: 800,
                size: 12,
                font: helveticaFont3,
                color: rgb(0, 0, 0),
            }
        );

        DDFthirdPage.drawText(
            `${this.folder?.requestNumber ? this.folder?.requestNumber : ''}`,
            {
                x: 35,
                y: 785,
                size: 12,
                font: helveticaFont3,
                color: rgb(0, 0, 0),
            }
        );


        // Tableau


        if (this.order.orderItems.length < 16) {

            let yName = 713;
            let yOthers = 700;

            this.order.orderItems.forEach(item => {


                let nameSplited = this.capitalizeWords(item.configurationTO.name.replace(',', ' ')).split(' ')
                let firstLine: string = '';
                let secondLine: string = '';
                let thirdLine: string = '';
                if (String(nameSplited.slice(0, 4)).replace(/,/g, ' ').length < 28) {
                    firstLine = String(nameSplited.slice(0, 5)).replace(/,/g, ' ');
                    secondLine = String(nameSplited.slice(5, 9)).replace(/,/g, ' ');
                    thirdLine = String(nameSplited.slice(9)).replace(/,/g, ' ');
                } else {
                    firstLine = String(nameSplited.slice(0, 4)).replace(/,/g, ' ');
                    secondLine = String(nameSplited.slice(4, 8)).replace(/,/g, ' ');
                    thirdLine = String(nameSplited.slice(8)).replace(/,/g, ' ');
                }

                DDFthirdPage.drawText(`${firstLine}`, {
                    x: 365,
                    y: yName,
                    size: 9.5,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                })

                yName = yName - 14.275

                DDFthirdPage.drawText(`${secondLine}`, {
                    x: 365,
                    y: yName,
                    size: 10,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                })

                yName = yName - 14.275

                DDFthirdPage.drawText(`${thirdLine}`, {
                    x: 365,
                    y: yName,
                    size: 10,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                });

                yName = yName - 14.275;

                const truncatedConfigPrice = Math.trunc((item.sellingPrice / item.quantity) * 100) / 100;
                DDFthirdPage.drawText(`${this.addSeparation(truncatedConfigPrice)} DZD`, {
                    x: 183,
                    y: yOthers,
                    size: 10,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                });

                DDFthirdPage.drawText(`${item.quantity}`, {
                    x: 140,
                    y: yOthers,
                    size: 10,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                });

                const truncatedSellingPrice = Math.trunc((item.sellingPrice) * 100) / 100;
                DDFthirdPage.drawText(`${this.addSeparation(truncatedSellingPrice)} DZD`, {
                    x: 40,
                    y: yOthers,
                    size: 10,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                })

                yOthers = yOthers - 43


            })
        } else {
            //4th Page
            let yName = 713;
            let yOthers = 700;

            let yName4th = 778;
            let yOthers4th = 765;


            let firstItemsPart: OrderItemModel[] = this.order.orderItems.slice(0, 15);
            let secondItemsPart: OrderItemModel[] = this.order.orderItems.slice(15);

            firstItemsPart.forEach(item => {

                let nameSplited = this.capitalizeWords(item.configurationTO.name.replace(',', ' ')).split(' ')
                let firstLine: string = '';
                let secondLine: string = '';
                let thirdLine: string = '';
                if (String(nameSplited.slice(0, 4)).replace(/,/g, ' ').length < 28) {
                    firstLine = String(nameSplited.slice(0, 5)).replace(/,/g, ' ');
                    secondLine = String(nameSplited.slice(5, 9)).replace(/,/g, ' ');
                    thirdLine = String(nameSplited.slice(9)).replace(/,/g, ' ');
                } else {
                    firstLine = String(nameSplited.slice(0, 4)).replace(/,/g, ' ');
                    secondLine = String(nameSplited.slice(4, 8)).replace(/,/g, ' ');
                    thirdLine = String(nameSplited.slice(8)).replace(/,/g, ' ');
                }

                DDFthirdPage.drawText(`${firstLine}`, {
                    x: 365,
                    y: yName,
                    size: 9.5,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                })

                yName = yName - 14.275

                DDFthirdPage.drawText(`${secondLine}`, {
                    x: 365,
                    y: yName,
                    size: 10,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                })

                yName = yName - 14.275

                DDFthirdPage.drawText(`${thirdLine}`, {
                    x: 365,
                    y: yName,
                    size: 10,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                })

                yName = yName - 14.275


                // DDFthirdPage.drawText(`${item.stockDepotId ? this.getStockNameByStockId(item.stockDepotId) : 'Stock EK'}`, {
                // x: 280,
                // y: yTable,
                // size: 10,
                // font: helveticaFont3,
                // color: rgb(0, 0, 0),
                // })
                const truncatedConfigPrice2 = Math.trunc((item.configurationTO.price / 1.19) * 100) / 100;
                DDFthirdPage.drawText(`${this.addSeparation(truncatedConfigPrice2)} DZD`, {
                    x: 183,
                    y: yOthers,
                    size: 10,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                })

                DDFthirdPage.drawText(`${item.quantity}`, {
                    x: 140,
                    y: yOthers,
                    size: 10,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                });

                const truncatedSellingPrice2 = Math.trunc((item.sellingPrice / 1.19) * 100) / 100;
                DDFthirdPage.drawText(`${this.addSeparation(truncatedSellingPrice2)} DZD`, {
                    x: 40,
                    y: yOthers,
                    size: 10,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                })

                yOthers = yOthers - 43;


            })
            secondItemsPart.forEach(item => {

                let nameSplited = this.capitalizeWords(item.configurationTO.name.replace(',', ' ')).split(' ')
                let firstLine: string = '';
                let secondLine: string = '';
                let thirdLine: string = '';
                if (String(nameSplited.slice(0, 4)).replace(/,/g, ' ').length < 28) {
                    firstLine = String(nameSplited.slice(0, 5)).replace(/,/g, ' ');
                    secondLine = String(nameSplited.slice(5, 9)).replace(/,/g, ' ');
                    thirdLine = String(nameSplited.slice(9)).replace(/,/g, ' ');
                } else {
                    firstLine = String(nameSplited.slice(0, 4)).replace(/,/g, ' ');
                    secondLine = String(nameSplited.slice(4, 8)).replace(/,/g, ' ');
                    thirdLine = String(nameSplited.slice(8)).replace(/,/g, ' ');
                }

                DDFFourthPage.drawText(`${firstLine}`, {
                    x: 365,
                    y: yName4th,
                    size: 9.5,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                })

                yName4th = yName4th - 14.275

                DDFFourthPage.drawText(`${secondLine}`, {
                    x: 365,
                    y: yName4th,
                    size: 10,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                })

                yName4th = yName4th - 14.275

                DDFFourthPage.drawText(`${thirdLine}`, {
                    x: 365,
                    y: yName4th,
                    size: 10,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                })

                yName4th = yName4th - 14.275


                // DDFFourthPage.drawText(`${item.stockDepotId ? this.getStockNameByStockId(item.stockDepotId) : 'Stock EK'}`, {
                // x: 280,
                // y: yTable,
                // size: 10,
                // font: helveticaFont3,
                // color: rgb(0, 0, 0),
                // })

                const truncatedConfigPrice3 = Math.trunc((item.configurationTO.price / 1.19) * 100) / 100;
                DDFFourthPage.drawText(`${this.addSeparation(truncatedConfigPrice3)} DZD`, {
                    x: 183,
                    y: yOthers4th,
                    size: 10,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                })

                DDFFourthPage.drawText(`${item.quantity}`, {
                    x: 140,
                    y: yOthers4th,
                    size: 10,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                })

                const truncatedSellingPrice3 = Math.trunc((item.sellingPrice / 1.19) * 100) / 100;
                DDFFourthPage.drawText(`${this.addSeparation(truncatedSellingPrice3)} DZD`, {
                    x: 40,
                    y: yOthers4th,
                    size: 10,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                })

                yOthers4th = yOthers4th - 43


            })
        }

        let categoriess = Array.from(new Set(this.categoriess));

        const numberOfUniqueItems: number = this.order?.orderItems?.length

        if (numberOfUniqueItems < 6) {
            if (categoriess?.length <= 3) {
                const firstLineCategories = categoriess?.join(", "); // Join the first four categories with commas
                const canvas = document.createElement("canvas");
                const context = canvas.getContext("2d");
                context!.font = `${10}px ${helveticaFont3}`;

                const firstLineTextWidth = context!.measureText(firstLineCategories).width;
                const adjustedXFirstLine = 190 - firstLineTextWidth / 2;
                DDFthirdPage.drawText(firstLineCategories ? firstLineCategories : '', {
                    x: adjustedXFirstLine,
                    y: 331,
                    size: 9,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                });
            } else {
                const firstLineCategories = categoriess?.slice(0, 3).join("+ "); // Join the first four categories with commas
                const remainingCategories = categoriess?.slice(3).join("+ "); // Join the remaining categories with commas

                const canvas = document.createElement("canvas");
                const context = canvas.getContext("2d");
                context!.font = `${10}px ${helveticaFont3}`;

                const firstLineTextWidth = context!.measureText(firstLineCategories).width;
                const secondLineTextWidth = context!.measureText(remainingCategories)
                    .width;

                const adjustedXFirstLine = 190 - firstLineTextWidth / 2;
                const adjustedXSecondLine = 180 - secondLineTextWidth / 2;

                DDFthirdPage.drawText(firstLineCategories ? firstLineCategories : '', {
                    x: adjustedXFirstLine,
                    y: 336,
                    size: 9,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                });
                DDFthirdPage.drawText(remainingCategories ? remainingCategories : '', {
                    x: adjustedXSecondLine,
                    y: 318,
                    size: 9,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                });
            }

            DDFthirdPage.drawText(
                `${
                    this.credit?.total
                        ? this.addSeparation(Math.trunc(100 * this.credit?.total) / 100)
                        : ''
                }`,
                {
                    x: 420,
                    y: 302,
                    size: 10,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                }
            );
            DDFthirdPage.drawText(`دج`, {
                x: 380,
                y: 302,
                size: 10,
                font: arabicFont3,
                color: rgb(0, 0, 0),
            });

            let numberOfMonths = this.order?.numberOfMonths;
            /* switch (this.order?.numberOfMonths) {
                      case 'ONE_YEARS': {
                        numberOfMonths = 12;
                        break;
                      }
                      case 'TWO_YEARS': {
                        numberOfMonths = 24;
                        break;
                      }
                      case 'THREE_YEARS': {
                        numberOfMonths = 36;
                        break;
                      }
                    } */

            DDFthirdPage.drawText(`${numberOfMonths ? numberOfMonths : ""}`, {
                x: 190,
                y: 302,
                size: 11,
                font: helveticaFont3,
                color: rgb(0, 0, 0),
            });
            DDFthirdPage.drawText(`شهر`, {
                x: 165,
                y: 302,
                size: 11,
                font: arabicFont3,
                color: rgb(0, 0, 0),
            });

            DDFthirdPage.drawText(
                `${this.order.pointOfSaleTo?.posCommune ? this.order.pointOfSaleTo?.posCommune : ''}`,
                {
                    x: 400,
                    y: 80,
                    size: 13,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                }
            );

            DDFthirdPage.drawText(
                `${this.order.createdAt ? this.datepipe.transform(new Date(this.order.createdAt), "yyyy/MM/dd") : ''}`,
                {
                    x: 180,
                    y: 80,
                    size: 13,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                }
            );
        } else if (numberOfUniqueItems >= 6 && numberOfUniqueItems < 11) {
            if (categoriess?.length <= 3) {
                const firstLineCategories = categoriess?.join(", "); // Join the first four categories with commas
                const canvas = document.createElement("canvas");
                const context = canvas.getContext("2d");
                context!.font = `${10}px ${helveticaFont3}`;

                const firstLineTextWidth = context!.measureText(firstLineCategories).width;
                const adjustedXFirstLine = 190 - firstLineTextWidth / 2;
                DDFFourthPage.drawText(firstLineCategories ? firstLineCategories : '', {
                    x: adjustedXFirstLine,
                    y: 757,
                    size: 9,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                });
            } else {
                const firstLineCategories = categoriess?.slice(0, 3).join("+ "); // Join the first four categories with commas
                const remainingCategories = categoriess?.slice(3).join("+ "); // Join the remaining categories with commas

                const canvas = document.createElement("canvas");
                const context = canvas.getContext("2d");
                context!.font = `${10}px ${helveticaFont3}`;

                const firstLineTextWidth = context!.measureText(firstLineCategories).width;
                const secondLineTextWidth = context!.measureText(remainingCategories)
                    .width;

                const adjustedXFirstLine = 190 - firstLineTextWidth / 2;
                const adjustedXSecondLine = 180 - secondLineTextWidth / 2;

                DDFFourthPage.drawText(firstLineCategories ? firstLineCategories : '', {
                    x: adjustedXFirstLine,
                    y: 762,
                    size: 9,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                });
                DDFFourthPage.drawText(remainingCategories ? remainingCategories : '', {
                    x: adjustedXSecondLine,
                    y: 744,
                    size: 9,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                });
            }

            DDFFourthPage.drawText(
                `${
                    this.credit?.total
                        ? this.addSeparation(Math.round(100 * this.credit?.total) / 100)
                        : ""
                }`,
                {
                    x: 420,
                    y: 728,
                    size: 10,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                }
            );
            DDFFourthPage.drawText(`دج`, {
                x: 380,
                y: 728,
                size: 10,
                font: arabicFont3,
                color: rgb(0, 0, 0),
            });

            let numberOfMonths = this.order?.numberOfMonths;
            /* switch (this.order?.numberOfMonths) {
                      case 'ONE_YEARS': {
                        numberOfMonths = 12;
                        break;
                      }
                      case 'TWO_YEARS': {
                        numberOfMonths = 24;
                        break;
                      }
                      case 'THREE_YEARS': {
                        numberOfMonths = 36;
                        break;
                      }
                    } */

            DDFFourthPage.drawText(`${numberOfMonths ? numberOfMonths : ""}`, {
                x: 190,
                y: 728,
                size: 11,
                font: helveticaFont3,
                color: rgb(0, 0, 0),
            });
            DDFFourthPage.drawText(`شهر`, {
                x: 165,
                y: 728,
                size: 11,
                font: arabicFont3,
                color: rgb(0, 0, 0),
            });

            DDFFourthPage.drawText(
                `${this.order.pointOfSaleTo?.posCommune ? this.order.pointOfSaleTo?.posCommune : ''}`,
                {
                    x: 400,
                    y: 461,
                    size: 13,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                }
            );

            DDFFourthPage.drawText(
                `${this.folder?.requestNumber ? this.folder?.requestNumber : ''}`,
                {
                    x: 245,
                    y: 810,
                    size: 12,
                    font: helveticaFont,
                    color: rgb(0, 0, 0),
                }
            );

            DDFFourthPage.drawText(
                `${this.order.createdAt ? this.datepipe.transform(new Date(this.order.createdAt), "yyyy/MM/dd") : ''}`,
                {
                    x: 180,
                    y: 461,
                    size: 13,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                }
            );
        } else if (numberOfUniqueItems >= 11 && numberOfUniqueItems < 16) {
            if (categoriess?.length <= 3) {
                const firstLineCategories = categoriess?.join(", "); // Join the first four categories with commas
                const canvas = document.createElement("canvas");
                const context = canvas.getContext("2d");
                context!.font = `${10}px ${helveticaFont3}`;

                const firstLineTextWidth = context!.measureText(firstLineCategories).width;
                const adjustedXFirstLine = 190 - firstLineTextWidth / 2;
                DDFFourthPage.drawText(firstLineCategories ? firstLineCategories : '', {
                    x: adjustedXFirstLine,
                    y: 638,
                    size: 9,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                });
            } else {
                const firstLineCategories = categoriess?.slice(0, 3).join("+ "); // Join the first four categories with commas
                const remainingCategories = categoriess?.slice(3).join("+ "); // Join the remaining categories with commas

                const canvas = document.createElement("canvas");
                const context = canvas.getContext("2d");
                context!.font = `${10}px ${helveticaFont3}`;

                const firstLineTextWidth = context!.measureText(firstLineCategories).width;
                const secondLineTextWidth = context!.measureText(remainingCategories)
                    .width;

                const adjustedXFirstLine = 190 - firstLineTextWidth / 2;
                const adjustedXSecondLine = 180 - secondLineTextWidth / 2;

                DDFFourthPage.drawText(firstLineCategories ? firstLineCategories : '', {
                    x: adjustedXFirstLine,
                    y: 643,
                    size: 9,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                });
                DDFFourthPage.drawText(remainingCategories ? remainingCategories : '', {
                    x: adjustedXSecondLine,
                    y: 625,
                    size: 9,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                });
            }

            DDFFourthPage.drawText(
                `${
                    this.credit?.total
                        ? this.addSeparation(Math.round(100 * this.credit?.total) / 100)
                        : ""
                }`,
                {
                    x: 420,
                    y: 609,
                    size: 10,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                }
            );
            DDFFourthPage.drawText(`دج`, {
                x: 380,
                y: 609,
                size: 10,
                font: arabicFont3,
                color: rgb(0, 0, 0),
            });

            let numberOfMonths = this.order?.numberOfMonths;
            /* switch (this.order?.numberOfMonths) {
                      case 'ONE_YEARS': {
                        numberOfMonths = 12;
                        break;
                      }
                      case 'TWO_YEARS': {
                        numberOfMonths = 24;
                        break;
                      }
                      case 'THREE_YEARS': {
                        numberOfMonths = 36;
                        break;
                      }
                    } */

            DDFFourthPage.drawText(`${numberOfMonths ? numberOfMonths : ""}`, {
                x: 190,
                y: 609,
                size: 11,
                font: helveticaFont3,
                color: rgb(0, 0, 0),
            });
            DDFFourthPage.drawText(`شهر`, {
                x: 165,
                y: 609,
                size: 11,
                font: arabicFont3,
                color: rgb(0, 0, 0),
            });

            DDFFourthPage.drawText(
                `${this.order.pointOfSaleTo?.posCommune ? this.order.pointOfSaleTo?.posCommune : ''}`,
                {
                    x: 400,
                    y: 343.5,
                    size: 13,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                }
            );

            DDFFourthPage.drawText(
                `${this.order.createdAt ? this.datepipe.transform(new Date(this.order.createdAt), "yyyy/MM/dd") : ''}`,
                {
                    x: 180,
                    y: 343.5,
                    size: 13,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                }
            );

            DDFFourthPage.drawText(
                `${this.folder?.requestNumber ? this.folder?.requestNumber : ''}`,
                {
                    x: 245,
                    y: 810,
                    size: 12,
                    font: helveticaFont,
                    color: rgb(0, 0, 0),
                }
            );
        } else if (numberOfUniqueItems >= 16 && numberOfUniqueItems < 21) {
            if (categoriess?.length <= 3) {
                const firstLineCategories = categoriess?.join(", "); // Join the first four categories with commas
                const canvas = document.createElement("canvas");
                const context = canvas.getContext("2d");
                context!.font = `${10}px ${helveticaFont3}`;

                const firstLineTextWidth = context!.measureText(firstLineCategories).width;
                const adjustedXFirstLine = 190 - firstLineTextWidth / 2;
                DDFFourthPage.drawText(firstLineCategories ? firstLineCategories : '', {
                    x: adjustedXFirstLine,
                    y: 399,
                    size: 9,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                });
            } else {
                const firstLineCategories = categoriess?.slice(0, 3).join("+ "); // Join the first four categories with commas
                const remainingCategories = categoriess?.slice(3).join("+ "); // Join the remaining categories with commas

                const canvas = document.createElement("canvas");
                const context = canvas.getContext("2d");
                context!.font = `${10}px ${helveticaFont3}`;

                const firstLineTextWidth = context!.measureText(firstLineCategories).width;
                const secondLineTextWidth = context!.measureText(remainingCategories)
                    .width;

                const adjustedXFirstLine = 190 - firstLineTextWidth / 2;
                const adjustedXSecondLine = 180 - secondLineTextWidth / 2;

                DDFFourthPage.drawText(firstLineCategories ? firstLineCategories : '', {
                    x: adjustedXFirstLine,
                    y: 404,
                    size: 9,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                });
                DDFFourthPage.drawText(remainingCategories ? remainingCategories : '', {
                    x: adjustedXSecondLine,
                    y: 386,
                    size: 9,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                });
            }

            DDFFourthPage.drawText(
                `${
                    this.credit?.total
                        ? this.addSeparation(Math.round(100 * this.credit?.total) / 100)
                        : ""
                }`,
                {
                    x: 420,
                    y: 370,
                    size: 10,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                }
            );
            DDFFourthPage.drawText(`دج`, {
                x: 380,
                y: 370,
                size: 10,
                font: arabicFont3,
                color: rgb(0, 0, 0),
            });

            let numberOfMonths = this.order?.numberOfMonths;
            /* switch (this.order?.numberOfMonths) {
                      case 'ONE_YEARS': {
                        numberOfMonths = 12;
                        break;
                      }
                      case 'TWO_YEARS': {
                        numberOfMonths = 24;
                        break;
                      }
                      case 'THREE_YEARS': {
                        numberOfMonths = 36;
                        break;
                      }
                    } */

            DDFFourthPage.drawText(`${numberOfMonths ? numberOfMonths : ""}`, {
                x: 190,
                y: 370,
                size: 11,
                font: helveticaFont3,
                color: rgb(0, 0, 0),
            });
            DDFFourthPage.drawText(`شهر`, {
                x: 165,
                y: 370,
                size: 11,
                font: arabicFont3,
                color: rgb(0, 0, 0),
            });

            DDFFourthPage.drawText(
                `${this.order.pointOfSaleTo?.posCommune ? this.order.pointOfSaleTo?.posCommune : ''}`,
                {
                    x: 400,
                    y: 103,
                    size: 13,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                }
            );

            DDFFourthPage.drawText(
                `${this.order.createdAt ? this.datepipe.transform(new Date(this.order.createdAt), "yyyy/MM/dd") : ''}`,
                {
                    x: 180,
                    y: 103,
                    size: 13,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                }
            );

            DDFFourthPage.drawText(
                `${this.folder?.requestNumber ? this.folder?.requestNumber : ''}`,
                {
                    x: 245,
                    y: 810,
                    size: 12,
                    font: helveticaFont,
                    color: rgb(0, 0, 0),
                }
            );
        } else if (numberOfUniqueItems >= 21) {

            DDFFourthPage.drawText(
                `${this.folder?.requestNumber ? this.folder?.requestNumber : ''}`,
                {
                    x: 245,
                    y: 810,
                    size: 12,
                    font: helveticaFont,
                    color: rgb(0, 0, 0),
                }
            );

            if (categoriess?.length <= 3) {
                const firstLineCategories = categoriess?.join(", "); // Join the first four categories with commas
                const canvas = document.createElement("canvas");
                const context = canvas.getContext("2d");
                context!.font = `${10}px ${helveticaFont3}`;

                const firstLineTextWidth = context!.measureText(firstLineCategories).width;
                const adjustedXFirstLine = 190 - firstLineTextWidth / 2;
                DDFFourthPage.drawText(firstLineCategories ? firstLineCategories : '', {
                    x: adjustedXFirstLine,
                    y: 201.5,
                    size: 9,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                });
            } else {
                const firstLineCategories = categoriess?.slice(0, 3).join("+ "); // Join the first four categories with commas
                const remainingCategories = categoriess?.slice(3).join("+ "); // Join the remaining categories with commas

                const canvas = document.createElement("canvas");
                const context = canvas.getContext("2d");
                context!.font = `${10}px ${helveticaFont3}`;

                const firstLineTextWidth = context!.measureText(firstLineCategories).width;
                const secondLineTextWidth = context!.measureText(remainingCategories)
                    .width;

                const adjustedXFirstLine = 190 - firstLineTextWidth / 2;
                const adjustedXSecondLine = 180 - secondLineTextWidth / 2;

                DDFFourthPage.drawText(firstLineCategories ? firstLineCategories : '', {
                    x: adjustedXFirstLine,
                    y: 206.5,
                    size: 9,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                });
                DDFFourthPage.drawText(remainingCategories ? remainingCategories : '', {
                    x: adjustedXSecondLine,
                    y: 188.5,
                    size: 9,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                });
            }

            DDFFourthPage.drawText(
                `${
                    this.credit?.total
                        ? this.addSeparation(Math.round(100 * this.credit?.total) / 100)
                        : ""
                }`,
                {
                    x: 420,
                    y: 172.5,
                    size: 10,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                }
            );
            DDFFourthPage.drawText(`دج`, {
                x: 380,
                y: 172.5,
                size: 10,
                font: arabicFont3,
                color: rgb(0, 0, 0),
            });

            let numberOfMonths = this.order?.numberOfMonths;
            /* switch (this.order?.numberOfMonths) {
                      case 'ONE_YEARS': {
                        numberOfMonths = 12;
                        break;
                      }
                      case 'TWO_YEARS': {
                        numberOfMonths = 24;
                        break;
                      }
                      case 'THREE_YEARS': {
                        numberOfMonths = 36;
                        break;
                      }
                    } */

            DDFFourthPage.drawText(`${numberOfMonths ? numberOfMonths : ""}`, {
                x: 190,
                y: 172.5,
                size: 11,
                font: helveticaFont3,
                color: rgb(0, 0, 0),
            });
            DDFFourthPage.drawText(`شهر`, {
                x: 165,
                y: 172.5,
                size: 11,
                font: arabicFont3,
                color: rgb(0, 0, 0),
            });

            DDFFifthPage.drawText(
                `${this.order.pointOfSaleTo?.posCommune ? this.order.pointOfSaleTo?.posCommune : ''}`,
                {
                    x: 400,
                    y: 652,
                    size: 13,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                }
            );
            DDFFifthPage.drawText(
                `${this.order.createdAt ? this.datepipe.transform(new Date(this.order.createdAt), "yyyy/MM/dd") : ''}`,
                {
                    x: 180,
                    y: 652,
                    size: 13,
                    font: helveticaFont3,
                    color: rgb(0, 0, 0),
                }
            );

            DDFFifthPage.drawText(
                `${this.folder?.requestNumber ? this.folder?.requestNumber : ''}`,
                {
                    x: 245,
                    y: 810,
                    size: 12,
                    font: helveticaFont,
                    color: rgb(0, 0, 0),
                }
            );
        }

        //Fiche De Signature
        const ficheDeSignature = await fetch(this.ficheDeSignature).then((res) =>
            res.arrayBuffer()
        );
        const FDS = await PDFDocument.load(ficheDeSignature);
        FDS.registerFontkit(fontkit);
        const arabicFont4 = await FDS.embedFont(fontBytes);
        const helveticaFont4 = await FDS.embedFont(StandardFonts.HelveticaBold);

        // var image: any;
        // if(this.image) {
        //   const imageBytes = await fetch(this.image).then((res) => res.arrayBuffer());
        //   image = await FDS.embedJpg(imageBytes)
        // } else {
        //   image = ''
        // }

        const FDSpages = FDS.getPages();
        const FDSfirstPage = FDSpages[0];

        FDSfirstPage.drawText(
            `${this.folder?.requestNumber ? this.folder?.requestNumber : ''}`,
            {
                x: 17,
                y: 740,
                size: 15,
                font: helveticaFont4,
                color: rgb(0, 0, 0),
            }
        );

        var x = 120;

        // FDSfirstPage.drawText(
        //   `${this.information?.financialSituation?.accountNumber}`,
        //   {
        //     x: x - 10,
        //     y: 625,
        //     size: 12,
        //     font: helveticaFont4,
        //     color: rgb(0, 0, 0),
        //   }
        // );

        FDSfirstPage.drawText(
            `${
                this.information?.personnelInformation?.fistNameEnArab
                    ? this.information?.personnelInformation?.fistNameEnArab!
                    : ""
            }   ${
                this.information?.personnelInformation?.lastNameEnArab
                    ? this.information?.personnelInformation?.lastNameEnArab
                    : ""
            }`,
            {
                x: this.getX(
                    x + 62,
                    this.information?.personnelInformation?.lastNameEnArab! +
                    this.information?.personnelInformation?.fistNameEnArab!,
                    arabicFont4,
                    12
                ),
                y: 605,
                size: 12,
                font: arabicFont4,
                color: rgb(0, 0, 0),
            }
        );

        // FDSfirstPage.drawText(`${this.information?.professionalSituation?.profession ? this.professionTranslatePipe.transform(this.information?.professionalSituation.profession) : ''}`, {
        //   x: 75,
        //   y: 569,
        //   size: 11,
        //   font: helveticaFont4,
        //   color: rgb(0, 0, 0),
        // });

        if (this.information?.personnelSituation?.personnelSituationAddress) {
            const adress =
                this.invertNumbers(this.information?.personnelSituation?.personnelSituationAddress!)!.split(
                    /\s+/
                );
            var firstPart =
                this.numberTrim(adress[0]) +
                " " +
                this.numberTrim(adress[1]) +
                " " +
                this.numberTrim(adress[2]);
            var secondPart = String(adress.slice(3).join(" "));
            if (firstPart.length < 14) {
                firstPart =
                    this.numberTrim(adress[0]) +
                    " " +
                    this.numberTrim(adress[1]) +
                    " " +
                    this.numberTrim(adress[2]) +
                    " " +
                    this.numberTrim(adress[3])
                // +
                // " " +
                // this.numberTrim(adress[4]);
                secondPart = String(adress.slice(4).join(" "));
            }

            let firstPartArray = firstPart.split(" ")
            firstPart = firstPartArray.filter(word => word !== 'undefined').join(" ")
            let secondPartArray = secondPart.split(" ")
            secondPart = secondPartArray.filter(word => word !== 'undefined').join(" ")


            FDSfirstPage.drawText(`${firstPart}`, {
                x: this.getX(x + 100, firstPart, arabicFont4, 12),
                y: 552,
                size: 12,
                font: arabicFont4,
                color: rgb(0, 0, 0),
            });
            FDSfirstPage.drawText(`${secondPart}`, {
                x: this.getX(x + 100, secondPart, arabicFont4, 12),
                y: 535,
                size: 12,
                font: arabicFont4,
                color: rgb(0, 0, 0),
            });
        }


        // FDSfirstPage.drawText(
        //   `${this.order.pointOfSaleTo?.posCommune ? this.order.pointOfSaleTo?.posCommune : ''}`,
        //   {
        //     x: x + 102,
        //     y: 540.5,
        //     size: 11,
        //     font: helveticaFont4,
        //     color: rgb(0, 0, 0),
        //   }
        // );

        FDSfirstPage.drawText(
            `${this.order.createdAt ? this.datepipe.transform(new Date(this.order.createdAt), "dd/MM/yyyy") : ''}`,
            {
                x: x + 162,
                y: 539.5,
                size: 11,
                font: helveticaFont4,
                color: rgb(0, 0, 0),
            }
        );

        // if(image) {
        //   FDSfirstPage.drawImage(image, {
        //     x: 465,
        //     y: 530,
        //     width: 92,
        //     height: 88,
        //   });
        // }

        //Engagement
        const engagementDeSolde = await fetch(this.engagementDeSolde).then((res) =>
            res.arrayBuffer()
        );
        const EDS = await PDFDocument.load(engagementDeSolde);
        EDS.registerFontkit(fontkit);
        const arabicFont5 = await EDS.embedFont(fontBytes);
        const helveticaFont5 = await EDS.embedFont(StandardFonts.HelveticaBold);

        const EDSpages = EDS.getPages();
        const EDSfirstPage = EDSpages[0];

        EDSfirstPage.drawText(
            `${this.folder?.requestNumber ? this.folder?.requestNumber : ''}`,
            {
                x: 43,
                y: 710,
                size: 15,
                font: helveticaFont5,
                color: rgb(0, 0, 0),
            }
        );

        EDSfirstPage.drawText(
            `${
                this.information?.personnelInformation?.fistNameEnArab
                    ? this.information?.personnelInformation?.fistNameEnArab!
                    : ""
            }   ${
                this.information?.personnelInformation?.lastNameEnArab
                    ? this.information?.personnelInformation?.lastNameEnArab
                    : ""
            }`,
            {
                x: this.getX(
                    390,
                    this.information?.personnelInformation?.lastNameEnArab! +
                    this.information?.personnelInformation?.fistNameEnArab!,
                    arabicFont5,
                    13
                ),
                y: 616,
                size: 13,
                font: arabicFont5,
                color: rgb(0, 0, 0),
            }
        );

        EDSfirstPage.drawText(
            `${
                this.information?.personnelSituation?.personnelSituationAddress
                    ? this.invertNumbers(this.information?.personnelSituation?.personnelSituationAddress!)
                    : ""
            }`,
            {
                x: this.getX(
                    480,
                    this.invertNumbers(this.information?.personnelSituation?.personnelSituationAddress!)!,
                    arabicFont5,
                    13
                ),
                y: 590,
                size: 13,
                font: arabicFont5,
                color: rgb(0, 0, 0),
            }
        );
        EDSfirstPage.drawText(
            `${
                this.information?.personnelInformation?.idCardNumber
                    ? this.information?.personnelInformation?.idCardNumber
                    : ""
            }`,
            {
                x: 160,
                y: 562,
                size: 14,
                font: helveticaFont5,
                color: rgb(0, 0, 0),
            }
        );
        EDSfirstPage.drawText(
            `${
                // this.information?.personnelInformation?.deliveryDate!.length > 12
                //   ? this.datepipe.transform(
                //       this.information?.personnelInformation?.deliveryDate,
                //       'dd/MM/yyyy'
                //     )
                //   :
                this.information?.personnelInformation?.deliveryDate ? this.information?.personnelInformation?.deliveryDate : ''
            }`,
            {
                x: 360,
                y: 535,
                size: 14,
                font: helveticaFont5,
                color: rgb(0, 0, 0),
            }
        );
        EDSfirstPage.drawText(
            `${
                this.information?.personnelInformation?.deliveryDaira
                    ? this.information?.personnelInformation?.deliveryDaira
                    : ""
            }`,
            {
                x: 160,
                y: 535,
                size: 14,
                font: helveticaFont5,
                color: rgb(0, 0, 0),
            }
        );
        EDSfirstPage.drawText(
            `${this.information?.financialSituation?.accountNumber ? this.information?.financialSituation?.accountNumber : ""}`,
            {
                x: 280,
                y: 508,
                size: 14,
                font: helveticaFont5,
                color: rgb(0, 0, 0),
            }
        );
        EDSfirstPage.drawText(
            `${this.order.pointOfSaleTo?.posCommune ? this.order.pointOfSaleTo?.posCommune : ''}`,
            {
                x: 245,
                y: 252,
                size: 13,
                font: helveticaFont5,
                color: rgb(0, 0, 0),
            }
        );
        EDSfirstPage.drawText(
            `${this.order.createdAt ? this.datepipe.transform(new Date(this.order.createdAt), "yyyy/MM/dd") : ''}`,
            {
                x: 90,
                y: 252,
                size: 13,
                font: helveticaFont5,
                color: rgb(0, 0, 0),
            }
        );

        //FATCA
        const fatca = await fetch(this.fatca).then((res) => res.arrayBuffer());
        const FATCA = await PDFDocument.load(fatca);
        FATCA.registerFontkit(fontkit);
        const arabicFont6 = await FATCA.embedFont(fontBytes);
        const helveticaFont6 = await FATCA.embedFont(StandardFonts.HelveticaBold);

        const FATCApages = FATCA.getPages();
        const FATCAfirstPage = FATCApages[0];
        const FATCAsecondPage = FATCApages[1];
        const FATCAthirdPage = FATCApages[2];

        FATCAfirstPage.drawText(
            `${this.folder?.requestNumber ? this.folder?.requestNumber : ''}`,
            {
                x: 73,
                y: 672,
                size: 15,
                font: helveticaFont6,
                color: rgb(0, 0, 0),
            }
        );

        FATCAsecondPage.drawText(
            `${this.folder?.requestNumber ? this.folder?.requestNumber : ''}`,
            {
                x: 73,
                y: 770,
                size: 15,
                font: helveticaFont6,
                color: rgb(0, 0, 0),
            }
        );

        FATCAthirdPage.drawText(
            `${this.folder?.requestNumber ? this.folder?.requestNumber : ''}`,
            {
                x: 73,
                y: 762,
                size: 15,
                font: helveticaFont6,
                color: rgb(0, 0, 0),
            }
        );

        if (this.information.personnelInformation?.gender) {
            FATCAfirstPage.drawText(`X`, {
                x: this.information.personnelInformation?.gender === "Male" ? 280.5 : 428.3,
                y: 424,
                size: 13,
                font: helveticaFont6,
                color: rgb(0, 0, 0),
            });
        }

        FATCAfirstPage.drawText(
            `${
                this.information?.personnelInformation?.fistNameEnArab
                    ? this.information?.personnelInformation?.fistNameEnArab
                    : ""
            }`,
            {
                x: 110,
                y: 373,
                size: 10,
                font: arabicFont6,
                color: rgb(0, 0, 0),
            }
        );
        FATCAfirstPage.drawText(
            `${
                this.information?.personnelInformation?.lastNameEnArab
                    ? this.information?.personnelInformation?.lastNameEnArab
                    : ""
            }`,
            {
                x: 220,
                y: 373,
                size: 10,
                font: arabicFont6,
                color: rgb(0, 0, 0),
            }
        );
        // FATCAfirstPage.drawText(
        //   `${this.information?.personnelInformation?.firstName!}`,
        //   {
        //     x: this.getXFr(125,this.information?.personnelInformation?.firstName! , helveticaFont6, 12),
        //     y: 392,
        //     size: 8,
        //     font: helveticaFont6,
        //     color: rgb(0, 0, 0),
        //   }
        // );
        // FATCAfirstPage.drawText(
        //   `${this.information?.personnelInformation?.lastName!}`,
        //   {
        //     x: this.getXFr(60,this.information?.personnelInformation?.lastName! , helveticaFont6, 12),
        //     y: 392,
        //     size: 8,
        //     font: helveticaFont6,
        //     color: rgb(0, 0, 0),
        //   }
        // );
        FATCAfirstPage.drawText(
            `${
                // this.information?.personnelInformation?.birthDay!.length > 12
                //   ? this.datepipe.transform(
                //       this.information?.personnelInformation?.birthDay,
                //       'dd/MM/yyyy'
                //     )
                //   :
                this.information?.personnelInformation?.birthDay ? this.information?.personnelInformation?.birthDay : ''
            }`,
            {
                x: 330,
                y: 373,
                size: 10,
                font: helveticaFont6,
                color: rgb(0, 0, 0),
            }
        );
        FATCAfirstPage.drawText(
            `${
                this.information?.personnelInformation?.placeOfBrithArabic
                    ? this.information?.personnelInformation?.placeOfBrithArabic
                    : ""
            }`,
            {
                x: 455,
                y: 373,
                size: 10,
                font: arabicFont6,
                color: rgb(0, 0, 0),
            }
        );
        FATCAfirstPage.drawText(
            `Algérienne`,
            {
                x: 200,
                y: 340,
                size: 10,
                font: helveticaFont6,
                color: rgb(0, 0, 0),
            }
        );

        FATCAfirstPage.drawText(
            `${
                this.information?.personnelSituation?.personnelSituationAddress
                    ? this.invertNumbers(this.information?.personnelSituation?.personnelSituationAddress!)
                    : ""
            }`,
            {
                x: 200,
                y: 305,
                size: 10,
                font: arabicFont6,
                color: rgb(0, 0, 0),
            }
        );
        FATCAfirstPage.drawText(
            `${
                this.information?.personnelSituation?.zipCode
                    ? this.information?.personnelSituation.zipCode
                    : ""
            }`,
            {
                x: 140,
                y: 265,
                size: 10,
                font: helveticaFont6,
                color: rgb(0, 0, 0),
            }
        );
        FATCAfirstPage.drawText(
            `${
                this.information?.personnelSituation?.personnelSituationCommune
                    ? this.information?.personnelSituation?.personnelSituationCommune
                    : ""
            }`,
            {
                x: 330,
                y: 265,
                size: 10,
                font: helveticaFont6,
                color: rgb(0, 0, 0),
            }
        );
        FATCAfirstPage.drawText(`Algérie`, {
            x: 450,
            y: 265,
            size: 10,
            font: helveticaFont6,
            color: rgb(0, 0, 0),
        });
        FATCAfirstPage.drawText(`${this.order?.phone ? this.order?.phone : ""}`, {
            x: 135,
            y: 185,
            size: 10,
            font: helveticaFont6,
            color: rgb(0, 0, 0),
        });
        FATCAfirstPage.drawText(`${this.client.email ? this.client.email : ""}`, {
            x: 150,
            y: 150,
            size: 10,
            font: helveticaFont6,
            color: rgb(0, 0, 0),
        });

        FATCAfirstPage.drawText(`X`, {
            x: 428,
            y: 117.5,
            size: 13,
            font: helveticaFont6,
            color: rgb(0, 0, 0),
        });

        FATCAsecondPage.drawText(`X`, {
            x: 424,
            y: 756,
            size: 13,
            font: helveticaFont6,
            color: rgb(0, 0, 0),
        });

        FATCAsecondPage.drawText(`X`, {
            x: 450,
            y: 570,
            size: 13,
            font: helveticaFont6,
            color: rgb(0, 0, 0),
        });

        FATCAsecondPage.drawText(`X`, {
            x: 450,
            y: 467,
            size: 13,
            font: helveticaFont6,
            color: rgb(0, 0, 0),
        });

        FATCAsecondPage.drawText(`X`, {
            x: 440,
            y: 390,
            size: 13,
            font: helveticaFont6,
            color: rgb(0, 0, 0),
        });

        FATCAthirdPage.drawText(`X`, {
            x: 81,
            y: 621.5,
            size: 13,
            font: helveticaFont6,
            color: rgb(0, 0, 0),
        });

        FATCAthirdPage.drawText(
            `${
                this.information?.informationAddress?.wilayaNameAscii
                    ? this.information?.informationAddress?.wilayaNameAscii
                    : ""
            }`,
            {
                x: 140,
                y: 380,
                size: 10,
                font: helveticaFont6,
                color: rgb(0, 0, 0),
            }
        );

        FATCAthirdPage.drawText(
            `${this.order.pointOfSaleTo?.posCommune ? this.order.pointOfSaleTo?.posCommune : ''}`,
            {
                x: 140,
                y: 380,
                size: 10,
                font: helveticaFont6,
                color: rgb(0, 0, 0),
            }
        );

        FATCAthirdPage.drawText(
            `${this.order.createdAt ? this.datepipe.transform(new Date(this.order.createdAt), "dd/MM/yyyy") : ''}`,
            {
                x: 340,
                y: 380,
                size: 10,
                font: helveticaFont6,
                color: rgb(0, 0, 0),
            }
        );

        // Autorisation de consultation de la centrale des riques
        const autorisationDeConsultation = await fetch(
            this.autorisationDeConsultation
        ).then((res) => res.arrayBuffer());
        const ACCR = await PDFDocument.load(autorisationDeConsultation);
        ACCR.registerFontkit(fontkit);
        const arabicFont7 = await ACCR.embedFont(fontBytes);
        const helveticaFont7 = await ACCR.embedFont(StandardFonts.HelveticaBold);

        const ACCRpages = ACCR.getPages();
        const ACCRfirstPage = ACCRpages[0];


        ACCRfirstPage.drawText(
            `${this.folder?.requestNumber ? this.folder?.requestNumber : ''}`,
            {
                x: 50,
                y: 725,
                size: 15,
                font: helveticaFont7,
                color: rgb(0, 0, 0),
            }
        );

        ACCRfirstPage.drawText(
            `${
                this.information?.personnelInformation?.fistNameEnArab
                    ? this.information?.personnelInformation?.fistNameEnArab!
                    : ""
            }   ${
                this.information?.personnelInformation?.lastNameEnArab
                    ? this.information?.personnelInformation?.lastNameEnArab
                    : ""
            }`,
            {
                x: this.getX(
                    370,
                    this.information?.personnelInformation?.lastNameEnArab! +
                    this.information?.personnelInformation?.fistNameEnArab,
                    arabicFont3,
                    14
                ),
                y: 599,
                size: 15,
                font: arabicFont7,
                color: rgb(0, 0, 0),
            }
        );

        ACCRfirstPage.drawText(
            `${
                // this.information?.personnelInformation?.birthDay!.length > 12
                //   ? this.datepipe.transform(
                //       this.information?.personnelInformation?.birthDay,
                //       'dd/MM/yyyy'
                //     )
                //   :
                this.information?.personnelInformation?.birthDay ? this.information?.personnelInformation?.birthDay : ''
            }`,
            {
                x: 340,
                y: 534,
                size: 14,
                font: helveticaFont7,
                color: rgb(0, 0, 0),
            }
        );
        ACCRfirstPage.drawText(
            `${
                this.information?.personnelInformation?.placeOfBrithArabic
                    ? this.information?.personnelInformation?.placeOfBrithArabic
                    : ""
            }`,
            {
                // x: 120,
                x: this.getX(230, this.information?.personnelInformation?.placeOfBrithArabic!, arabicFont3, 14),
                y: 537,
                size: 14,
                font: arabicFont7,
                color: rgb(0, 0, 0),
            }
        );
        ACCRfirstPage.drawText(
            `${
                this.information?.personnelSituation?.personnelSituationAddress
                    ? this.invertNumbers(this.information?.personnelSituation?.personnelSituationAddress!)
                    : ""
            }`,
            {
                x: this.getX(
                    490,
                    this.invertNumbers(this.information?.personnelSituation?.personnelSituationAddress!)!,
                    arabicFont3,
                    14
                ),
                y: 475,
                size: 14,
                font: arabicFont7,
                color: rgb(0, 0, 0),
            }
        );

        ACCRfirstPage.drawText(
            `${this.order.pointOfSaleTo?.posCommune ? this.order.pointOfSaleTo?.posCommune : ''}`,
            {
                x: 400,
                y: 248,
                size: 13,
                font: helveticaFont7,
                color: rgb(0, 0, 0),
            }
        );

        ACCRfirstPage.drawText(
            `${this.order.createdAt ? this.datepipe.transform(new Date(this.order.createdAt), "yyyy/MM/dd") : ''}`,
            {
                x: 260,
                y: 248,
                size: 13,
                font: helveticaFont7,
                color: rgb(0, 0, 0),
            }
        );

        //Saving Files To Download Them Modified

        const Bytes: any[] = [];

        const pdfBytes1 = await DOCB.save();
        this.saveByteArray(`Demande ouverture de compte - ${this.folder?.requestNumber ? this.folder?.requestNumber : ""}.pdf`, pdfBytes1);
        const pdfBytes2 = await ADP.save();
        this.saveByteArray(`Autorisation de prélèvement - ${this.folder?.requestNumber ? this.folder?.requestNumber : ""}.pdf`, pdfBytes2);
        // const pdfBytes8 = await ADPT.save();
        // this.saveByteArray("Autorisation de prélèvement 2 (Unique).pdf", pdfBytes8);
        const pdfBytes3 = await DDF.save();
        this.saveByteArray(`Demande de financement - ${this.folder?.requestNumber ? this.folder?.requestNumber : ""}.pdf`, pdfBytes3);
        const pdfBytes4 = await FDS.save();
        this.saveByteArray(`Fiche de signature - ${this.folder?.requestNumber ? this.folder?.requestNumber : ""}.pdf`, pdfBytes4);
        const pdfBytes5 = await EDS.save();
        this.saveByteArray(
            `Engagement de maintien solde suffisant sous compte CCP - ${this.folder?.requestNumber ? this.folder?.requestNumber : ""}.pdf`,
            pdfBytes5
        );
        const pdfBytes6 = await FATCA.save();
        this.saveByteArray(`FATCA - ${this.folder?.requestNumber ? this.folder?.requestNumber : ""}.pdf`, pdfBytes6);
        const pdfBytes7 = await ACCR.save();
        this.saveByteArray(
            `Autorisation de consultation de la centrale des risques - ${this.folder?.requestNumber ? this.folder?.requestNumber : ""}.pdf`,
            pdfBytes7
        );

        this.downLoadFiles$.next(false);
    }

    numberTrim(string: string) {
        let words = /\d/.test(string);
        if (words) {
            return String(string.split(/\s+/).reverse()).trim();
        } else {
            return string;
        }
    }


    // stringToDate(dateString: string): Date {
    //   const date = new Date(dateString)
    //   const formatedDate = String(this.datepipe.transform(date, 'dd/MM/yyyy') || '');
    //   const parts = formatedDate.includes('-') ? formatedDate.split('-') : formatedDate.split('/');
    //   const day = +parts[0];
    //   const month = +parts[1] - 1; // Months are 0-based in JavaScript Date
    //   const year = +parts[2];

    //   return new Date(year, month, day);
    // }

    invertNumbers(input: string): string {
        return input?.replace(/\d+/g, match => {
            return match?.split('').reverse().join('');
        });
    }

    capitalizeWords(input: string): string {
        return input.replace(/\b\w+\b/g, match => {
            return match.charAt(0).toUpperCase() + match.slice(1).toLowerCase();
        });
    }

    getStockNameByStockId(id: number): string | undefined {
        const stock = this.stocks.find((i) => i.id === id);
        return stock?.name;
    }

    saveByteArray(reportName: any, byte: any) {
        var blob = new Blob([byte], {type: "application/pdf"});
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        var fileName = reportName;
        link.download = fileName;
        link.click();
    }

    constructor(
        private documentService: DocumentService,
        private route: ActivatedRoute,
        private clientsService: ClientsService,
        private orderSplitService: OrderSplitService,
        private professionTranslatePipe: ProfessionTranslatePipe,
        private productsService: EkProductsService,
        private categoryService: EkCategoriesService,
        private stocksService: StocksService,
        public datepipe: DatePipe
    ) {
        this.currentRole = JSON.parse(localStorage.getItem("currentUser")).roles;
    }

    addSeparation(value: number): string {
        if (value) {
            const stringValue = value.toString();
            const separatedValue = stringValue.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            return separatedValue;
        } else {
            return "";
        }
    }

    getX(x: number, value: string, font: any, fontSize: number) {
        if (value) {
            const textWidth = font.widthOfTextAtSize(value, fontSize);
            const startX = x - textWidth;
            return startX;
        } else {
            return 0;
        }
    }

    getXFr(x: number, value: string, font: any, fontSize: number) {
        if (value) {
            const textWidth = font.widthOfTextAtSize(value, fontSize);
            const startX = x + textWidth;
            return startX;
        } else {
            return 0;
        }
    }

    formatNumber(num: number): string {
        const formattedNumber = num.toFixed(2);
        const parts = formattedNumber.split('.' || ',');
        const integerPart = parts[0].padStart(7, '0');
        const decimalPart = parts[1];
        return `${integerPart},${decimalPart}`;
    }

    ngOnInit() {
        this.route.paramMap.subscribe((params: any) => {
            this.folderId = Number(params.get("idFolder"));
            this.orderId = Number(params.get("idOrder"));
        });

       let currentDate = new Date();

        currentDate.setMonth(currentDate.getMonth() + 2);

        currentDate.setDate(27);


        this.datePrelevement = currentDate.toLocaleDateString('en-GB'); // Format: dd-MM-yyyy


        this.orderSplitService.orderSplit$.subscribe((res) => {
            if (res) {

                this.order = res;

                this.orderSplitService.getCredit(res.id).subscribe((cr) => {
                    this.credit = cr;
                });

                this.categoriess = [];

                res.orderItems.forEach(config => {
                    this.productsService.getById(config.configurationTO.productId).subscribe(product => {
                        if (product) {
                            this.categoryService.getParentCategoryById(product.categoryId).subscribe(category => {
                                if (category) {
                                    this.categoriess.push(category);
                                }
                            });
                        }
                    });
                });
            }
        });

        this.route.paramMap.subscribe((params: any) => {
            this.folderId = Number(params.get("idFolder"));
        });
        this.documentService
            .getDocumentsByFolderId(this.folderId)
            .subscribe((res: any) => {
                this.bankDocuments$.next(
                    res.body.filter(
                        (document: DocumentModel) => document.documentsType === "BANK"
                    )
                );
            });

        this.stocksService.getAll().subscribe(res => {
            this.stocks = res
        })


        this.documentService.downloadDocuments("BANK_EMPTY", this.orderId).subscribe({

            next: (res: any) => {
                let obj = res.body.find((o: any) => {
                    switch (o.documentsTitle) {
                        // Autorisation de prélèvement
                        case "DIRECT_DEBIT_AUTHORIZATION": {
                            this.autorisationDePrelevement = o.documentUrlPreSigned;
                            break;
                        }
                        // Autorisation de consultation de la centrale des risques
                        case "AUTHORIZATION_TO_CONSULT_THE_CREDIT_BUREAU": {
                            this.autorisationDeConsultation = o.documentUrlPreSigned;
                            break;
                        }
                        // facta
                        case "FACTA": {
                            this.fatca = o.documentUrlPreSigned;
                            break;
                        }
                        // Fiche de signature
                        case "SIGNATURE_SHEET": {
                            this.ficheDeSignature = o.documentUrlPreSigned;
                            break;
                        }
                        // Demande de financement
                        case "REQUEST_FOR_FINANCING": {
                            this.demandeDeFinancement = o.documentUrlPreSigned;
                            break;
                        }
                        // Demande ouverture de compte
                        case "ACCOUNT_OPENING_REQUEST": {
                            this.demandeOuvertureCompteB = o.documentUrlPreSigned;
                            break;
                        }
                        // Engagement de maintien solde suffisant sous compte CCP
                        case "COMMITMENT_TO_MAINTAIN_SUFFICIENT_BALANCE_UNDER_CCP_ACCOUNT": {
                            this.engagementDeSolde = o.documentUrlPreSigned;
                            break;
                        }
                    }
                });
            },
        });
    }
}


import {DownloadFileDialoguComponent} from './folder-step-two/download-file-dialogu/download-file-dialogu.component';
import {
    DocumentsMessagesPopupComponent
} from './../second-step/documents-messages-popup/documents-messages-popup.component';
import {CreditModel} from './../../../../../../core/ek-e-commerce/ek-models/credit.model';
import {MonthlyPaymentPipe} from './../../../../../../core/_base/layout/pipes/monthlyPayment.pipe';
import {CartMessages} from './folder-step-one/folder-step-one.component';
import {FormGroup, ValidationErrors} from '@angular/forms';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Actions, ofType} from '@ngrx/effects';
import {Update} from '@ngrx/entity';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {finalize, take} from 'rxjs/operators';

import {ShippingAddressFolder} from '../../../../../../../../../ek-guichet/src/app/Models/shipping-address-folder';
import {DocumentModel} from './../../../../../../core/ek-e-commerce/ek-models/document.model';
import {EkSnackBarService} from './../../../../../../core/services/ek-snackBar.service';
import {DocumentService} from './../../../../../../core/services/document.service';
import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {OrderSplitService} from '../../../../../../core/ek-e-commerce/ek-services/order-split.service';
import {OrderSplitAdmin} from '../../../../../../core/ek-e-commerce/ek-models/OrderSplitAdmin';
import {FolderModel} from '../../../../../../core/ek-e-commerce/ek-models/folder.model';
import {FoldersService} from '../../../../../../core/ek-e-commerce/ek-services/folders.service';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../../core/reducers';
import {OrderSplit} from '../../../../../../core/ek-e-commerce/ek-models/orderSplit';
import {ClientsService} from '../../../../../../core/ek-e-commerce/ek-services/clients.service';
import {LayoutUtilsService, MessageType,} from '../../../../../../core/_base/crud';
import {PointOfSaleModel} from '../../../../../../core/ek-e-commerce/ek-models/point-of-sale.model';
import {ClientModel} from '../../../../../../core/ek-e-commerce/ek-models/client.model';
import {
    OrderSplitCreated,
    OrderSplitCreatedSuccessfully,
    OrderSplitCreationFailed,
    OrderSplitUpdated,
    OrderSplitUpdatedSuccessfully,
    OrderSplitUpdateFailed,
} from '../../../../../../core/ek-e-commerce/ek-actions/orderSplit.actions';
import {ocrInfo} from '../../../../../../core/ek-e-commerce/ek-models/document-ocr-information';
import {OneByOneValidations,} from '../../../../../../core/ek-e-commerce/ek-models/ocr-validations';
import {OrderSplitUpdateAdmin} from '../../../../../../core/ek-e-commerce/ek-models/orderSplitUpdateAdmin';
import {selectLastCreatedOrderSplitId} from '../../../../../../core/ek-e-commerce/ek-selectors/orderSplit.selectors';
import {
    ClientOcrInformationService
} from '../../../../../../core/ek-e-commerce/ek-services/clientOcrInformation.service';
import {
    FilesSecondErrorPopupComponent
} from './folder-step-two/files-second-error-popup/files-second-error-popup.component';
import {
    SecondStepConfirmationPopupComponent
} from './folder-step-two/second-step-confirmation-popup/second-step-confirmation-popup.component';
import {FolderVerificationService} from '../../../../../../core/services/folder-verification.service';
import {EkClientEmailComponent} from '../../ek-customers/ek-client-email/ek-client-email.component';
import {DatePipe, DOCUMENT} from '@angular/common';
import {ProfessionTranslatePipe} from '../../../../../../core/_base/layout/pipes/profession-translate.pipe';
import {
    ScoringExceptionsInterpretPipe
} from '../../../../../../core/_base/layout/pipes/scoring-exceptions-interpret.pipe';
import {ActivityTranslatePipe} from '../../../../../../core/_base/layout/pipes/domain-translate.pipe';
import {BANK_LENDER} from '../../../Shared/Constants/bank.lender';
import {CustomersService} from '../../../../../../core/ek-e-commerce/ek-services/customers.service';
import {BANKER_STATES} from '../../../../../../../assets/constancies/banker.states';
import { PhoneNumberValidationPopupComponent } from '../phone-number-validation-popup/phone-number-validation-popup.component';
import {environment} from '../../../../../../../environments/environment';

enum errorCode {
    HTF = 'Vous avez dépasser la limite max d\'achat autorisé',

    LTF = 'Montant d\'achat inferieur à limite minimum autorisé',

    HTC = 'Montant de commande supérieur à la capacité d\'achat',

    LTC = 'LowerThanCapacity',

    HTM = 'Mensualité est supérieur à 30% du salaire'
}

@Component({
    selector: 'kt-folder-steps',
    templateUrl: './folder-steps.component.html',
    styleUrls: ['./folder-steps.component.scss'],
    providers: [MonthlyPaymentPipe, ProfessionTranslatePipe, ScoringExceptionsInterpretPipe, ActivityTranslatePipe]
})
export class FolderStepsComponent implements OnInit, OnDestroy {
    // Validation values
    oneByOneValidations: OneByOneValidations = {
        ficheFamilliale: true,
        extraitDeNaissance: true,
        residence: true,
        chifa: true,
        FichePaie1: true,
        FichePaie2: true,
        FichePaie3: true,
        ccp: true,
        attestationDeTravail: true,
        retraite: true,
        faces: true,
        rena: true,
        radiation: true,
        cni: true,
        cni_verso: true,
        releveDeCompte: true,
        cheque: true,
        incomeMilitary: true
    };

    min = 50000;
    max = 1000000;

    Subs: Subscription[] = [];

    ocrInfos!: ocrInfo;

    ocrInfosForm!: FormGroup;

    public OrdersTitle: string;

    step = 1;

    isNewOrder = false;
    isEditable!: boolean;
    folderShipping!: ShippingAddressFolder;
    validationLoading = false;
    isValid = false;

    orderCreationEntity: OrderSplitAdmin;
    client: ClientModel;
    clientId = 0;
    idFolder = 0;
    idOrder = 0;
    folder: FolderModel;
    orderSplit: OrderSplit;
    simulationForm: FormGroup;
    selectedRelay: PointOfSaleModel;
    showCartLimit = true;
    cartMessage: CartMessages = {
        isLimited: false,
        messages: '',
    };
    loadingFilesSubject = new BehaviorSubject<boolean>(false);
    readonly isExporting = this.loadingFilesSubject.asObservable();

    loadingRecapSubject = new BehaviorSubject<boolean>(false);
    readonly loadingRecap$ = this.loadingRecapSubject.asObservable();

    loadingSimulationSubject$ = new BehaviorSubject<boolean>(false);
    readonly loadingSimulationForm$ = this.loadingSimulationSubject$.asObservable();

    loadingScoringFormSubject$ = new BehaviorSubject<boolean>(false);
    readonly loadingScoringFormForm$ = this.loadingScoringFormSubject$.asObservable();

    loadingSubject$ = new BehaviorSubject<boolean>(false);
    readonly loading$ = this.loadingSubject$.asObservable();

    private _myData$ = new BehaviorSubject<ClientModel>(null);
    public clientSplitObs$ = this._myData$.asObservable();

    selectedOrderSubject = new BehaviorSubject<OrderSplit>(null);
    readonly selectedOrder$ = this.selectedOrderSubject.asObservable();

    personnalSituation: string;
    profSituation: string;
    loading: boolean;
    logsList: any;
    logs: any;
    civileDocuments: any;
    profDocuments: any;
    garantyDocuments: any;

    cartCapacity = null;
    bankDocuments: any;
    isChecked: boolean;
    noDucmentMissing: boolean;
    total: number;
    needSomeChanges: boolean = null as any;
    months: number;
    guaranteesChecked: boolean;
    invoicesChecked: boolean;
    currentRole = '';
    credit: CreditModel;
    canDownload: boolean;
    threeToTow = false;
    updateToTwo = false;

    FolderObs$: Observable<FolderModel>;
    requestNumber: string;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private activatedRoute: ActivatedRoute,
        private route: ActivatedRoute,
        private orderSplitService: OrderSplitService,
        public foldersService: FoldersService,
        private documentService: DocumentService,
        private router: Router,
        private store: Store<AppState>,
        private clientsService: ClientsService,
        private layoutUtilsService: LayoutUtilsService,
        private _actions$: Actions,
        private EkSnackBar: EkSnackBarService,
        private dialog: MatDialog,
        private snackBar: MatSnackBar,
        private clientOcrInformationService: ClientOcrInformationService,
        private datePipe: DatePipe,
        private cdRef: ChangeDetectorRef,
        private customersService: CustomersService,
        public folderVerificationService: FolderVerificationService,
        private mensualPipe: MonthlyPaymentPipe,
        private professionTranslatePipe: ProfessionTranslatePipe,
        private activityTranslatePipe: ActivityTranslatePipe,
        private scoringExceptionsInterpretPipe: ScoringExceptionsInterpretPipe,
    ) {
        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
    }

    ngOnInit(): void {

        this.threeToTow = false;
        this.updateToTwo = false;

        this.activatedRoute.paramMap.subscribe((res: ParamMap) => {
            this.OrdersTitle = this.activatedRoute.snapshot.data.title;
        });
        this.route.queryParams.subscribe((params: any) => {
            this.step = params.step ? Number(params.step) : 1;
        });

        // Empty Order - adding new order
        this.route.paramMap.subscribe((params) => {
            this.clientId = Number(params.get('idClient'));
            this.idOrder = Number(params.get('idOrder'));
            this.idFolder = Number(params.get('idFolder'));

            // new order
            if (this.clientId > 0) {
                this.isNewOrder = true;
                this.loadClient(this.clientId);
            } else {
                this.loadOrderSplitByIdService(this.idOrder);
            }
        });

        this.documentService
            .getDocumentsByFolderId(this.idFolder)
            .subscribe((res: any) => {
                if (res) {
                    this.folderVerificationService.documents$.next(res.body);
                }
            });

        this.documentService
            .getValidationResponse(this.idFolder)
            .subscribe((res) => {
                if (res) {
                    this.oneByOneValidations = this.folderVerificationService.verifyDocuments(res.body);
                    this.folderVerificationService.getMessages(res.body);
                    this.needSomeChanges = this.folderVerificationService.needSomeChanges;
                    const temp = this.areAllChecked(this.oneByOneValidations);
                    this.documentService.allOneByOneIsCheckedSubject.next(temp);
                    this.cdRef.detectChanges();
                }
            });

        this.Subs.push(
            this.documentService
                .getDocumentsByFolderId(this.idFolder)
                .subscribe((res: any) => {
                    this.civileDocuments = res.body.filter(
                        (document: DocumentModel) =>
                            document.documentsType === 'CIVILIAN_STATE'
                    );
                    this.profDocuments = res.body.filter(
                        (document: DocumentModel) =>
                            document.documentsType === 'PROFESSIONAL'
                    );
                    this.garantyDocuments = res.body.filter(
                        (document: DocumentModel) => document.documentsType === 'GUARANTEES'
                    );
                    this.bankDocuments = res.body.filter(
                        (document: DocumentModel) => document.documentsType === 'BANK'
                    );
                })
        );

        this.orderSplitService.createdNewOrder$.subscribe((res) => {
            if (res) {
                this.orderCreationEntity = res;
            }
        });

        // folder shipping address
        this.getShippingAddress();

        this.Subs.push(
            this.orderSplitService.selectedRelaySubject$.subscribe((change) => {
                this.selectedRelay = change;
            })
        );

        this.Subs.push(
            this.foldersService.selectedFolderSubject$.subscribe((res) => {
                if (res) {
                    this.folder = res;
                    this.requestNumber = res.requestNumber;
                    this.foldersService.isChecked(this.idFolder).subscribe((res) => {
                        if (res) {
                            if (res.body === false) {
                                // get client info from ocr
                                this.isChecked = true;
                                this.foldersService.isCheckSbj$.next(true);
                                this.getClientInformations();
                                this.cdRef.detectChanges();
                            } else {
                                this.documentService.ocrSubject.next(null as any);
                                this.isChecked = false;
                                this.foldersService.isCheckSbj$.next(false);
                                this.cdRef.detectChanges();
                            }
                        }
                    });
                }
            })
        );

        this.foldersService.folderStateSubj$.subscribe(state => {
            this.canDownload = state === 'EK_GUARTENTEES_PENDING_SIGNATURE' ||
                state === 'EK_CONTROL_BEFORE_GUARANTEES_PRINTING' ||
                state === 'EK_GUARTENTEES_SIGNED_IN_PROGRESS' ||
                state === 'EK_PHYSICAL_FOLDER_PENDING' ||
                state === 'EK_PHYSICAL_FOLDER_WAITING_FOR_PICK' ||
                state === 'EK_WAITING_FOR_CLIENT' ||
                state === 'EK_PHYSICAL_FOLDER_PICKEDUP' ||
                state === 'EK_PHYSICAL_FOLDER_IN_DELIVERY' ||
                state === 'EK_PHYSICAL_FOLDER_DELIVERED' ||
                state === 'EK_PHYSICAL_FOLDER_IN_PROGRESS' ||
                state === 'EK_BANK_PHYSICAL_FOLDER_IN_DELIVERY' ||
                state === 'EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS' ||
                state === 'EK_BANK_PHYSICAL_FOLDER_ACCEPTED' ||
                state === 'EK_BANK_PHYSICAL_FOLDER_RESERVE' ||
                state === 'EK_BANK_REFUSED' ||
                state === 'EK_FOLDER_REFUSED' ||
                state === 'EK_ADMIN_FOLDER_CANCELED' ||
                state === 'EK_CLIENT_NO_RESPONSE' ||
                state === 'FINANCIAL_FOLDER' ||
                state === 'EK_FOLDER_PICKUP_FAILED';
        });

        // if child component change order split
        this.orderSplitService.selectedOrderSubject.subscribe(res => {
            this.orderSplit = res;
        });

        // keep folder synch
        this.foldersService.folderSbj$.subscribe({
            next: (folder) => {
                this.FolderObs$ = of(folder);
            }
        });

    }


    checkCart() {
        this.cartMessage = {
            isLimited: false,
            messages: '',
        };
        this.total = this.orderSplitService.total;

        if (this.total < this.min && this.total < this.cartCapacity) {
            this.cartMessage.isLimited = true;
            this.cartMessage.messages = errorCode.LTF;
            return;
        }
        if (this.total > this.max && this.total < this.cartCapacity) {
            this.cartMessage.isLimited = true;
            this.cartMessage.messages = errorCode.HTF;
            return;
        }
        if (this.total > this.max || this.total > this.cartCapacity) {
            this.cartMessage.isLimited = true;
            this.cartMessage.messages = errorCode.HTC;
            return;
        }
        const selectedMonths = +this.simulationForm.controls.monthlyPayment.value;
        if (this.mensualPipe.transform(this.total, selectedMonths) > (+this.orderCreationEntity.salarySimulation * 0.28)) {
            this.cartMessage.isLimited = true;
            this.cartMessage.messages = errorCode.HTM;
            return;
        }

        return this.cartMessage;
    }

    getClientInformations() {
        // get client info from ocr
        this.Subs.push(
            this.documentService.getClientInfo(this.idFolder).subscribe({
                next: (res) => {
                    this.ocrInfos = res.body;
                    this.documentService.ocrSubject.next(res.body);
                    this.foldersService.clientInfoUpdated$.next(res.body.isInfoCompleted);
                    // this.dialog.closeAll();
                    this.cdRef.detectChanges();
                },
                error: (error) => {
                    this.loading = false;
                    const msg = 'Veuillez reéssayer à nouveau';
                    this.EkSnackBar.openSnackBar(msg, 'ok', 'error-snackbar');
                    // this.dialog.closeAll();
                },
            })
        );
    }

    manipulateStepOne() {

        const salary = this.simulationForm.controls.salary.value.replace(' ', '');
        this.simulationForm.controls.salary.setValue(salary);

        if (this.simulationForm && this.simulationForm.invalid) {
            Object.keys(this.simulationForm.controls).forEach((controlName) =>
                this.simulationForm.controls[controlName].markAsTouched()
            );

            const message = `vous devez remplir tous les champs obligatoires !`;
            this.layoutUtilsService.showActionNotification(
                message,
                MessageType.Update,
                5000,
                true,
                true
            );

            return;
        }

        this.checkCart();


        if (this.currentRole === 'ROLE_BANKER_EK') {
            this.step = 2;
            this.router.navigate([], {queryParams: {step: 2}});

        } else {
            if (this.isNewOrder) {
                this.createOrderSplit();
            } else {
                this.updateOrderSplitAdmin();
            }
        }

    }

    getSimulationInfoFG(event: FormGroup) {
        this.simulationForm = event;
    }

    verifyEdit(isEditable: boolean) {
        this.isEditable = isEditable;
    }

    validateShipping(folderShipping: ShippingAddressFolder) {
        this.folderShipping = folderShipping;
        this.isValid = true;
    }

    nextStep(nextStep) {

        switch (nextStep) {
            case 1:
                this.step = nextStep;
                this.router.navigate([], {queryParams: {step: nextStep}});
                break;
            case 2:
                if (this.threeToTow) {
                    this.threeToTow = false;
                    this.updateToTwo = true;
                    this.router.navigate([], {queryParams: {step: 2}});
                    this.updateClientInformationStep3();
                } else {
                    this.step = nextStep;
                    this.router.navigate([], {queryParams: {step: nextStep}});
                }

                break;
            case 3: {
                this.foldersService.isCheckSbj$.subscribe((res) => {
                    this.isChecked = res;
                });
                if (this.step > nextStep) {
                    this.step = nextStep;
                    this.router.navigate([], {queryParams: {step: nextStep}});
                } else {
                    this.documentService.ocrSubject.pipe(take(1)).subscribe((res) => {
                        if (res && this.isChecked) {
                            this.step = nextStep;
                            this.router.navigate([], {queryParams: {step: nextStep}});
                            return;
                        } else {
                            if (!this.isChecked) {
                                this.loading = true;
                                this.dialog.open(SecondStepConfirmationPopupComponent, {
                                    width: '480px',
                                });
                                this.Subs.push(
                                    this.documentService
                                        .verifyDocuments(this.idFolder)
                                        .subscribe({
                                            next: (res) => {
                                                this.loading = false;
                                                if (res.body) {
                                                    let keyFr = '';
                                                    this.dialog.closeAll();
                                                    Object.keys(res.body).forEach((key) => {
                                                        this.noDucmentMissing = true;
                                                        switch (key) {
                                                            case 'FAMILY_SHEET':
                                                                keyFr = 'Fiche Familliale';
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case 'FRONT_IDENTITY_CARD':
                                                                keyFr = 'Carte d\'identité (recto)';
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case 'BACK_IDENTITY_CARD':
                                                                keyFr = 'Carte d\'identité (verso)';
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case 'PHOTO_IDENTITY':
                                                                keyFr = 'Photo d\'identité';
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case 'RESIDENCE':
                                                                keyFr = 'Residence';
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case 'BIRTH_ACT':
                                                                keyFr = 'Extrait de naissance';
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case 'CARD_CHIFA':
                                                                keyFr = 'Carte chiffa';
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case 'CROSSED_CHECK':
                                                                keyFr = 'Chèque barré';
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case 'PAY_SLIP':
                                                                keyFr = 'Fiche de paie 1';
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case 'PAYSLIP_TWO':
                                                                keyFr = 'Fiche de paie 2';
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case 'PAYSLIP_THREE':
                                                                keyFr = 'Fiche de paie 3';
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case 'FEES_STATEMENT':
                                                                keyFr = 'Relevé des emoluments';
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case 'WORK_CERTIFICATE':
                                                                keyFr = 'Attestation de travail';
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case 'BANK_STATEMENT':
                                                                keyFr = 'Relevé bancaire';
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case 'DIRECT_DEBIT_CERTIFICATE':
                                                                keyFr = 'Autorisation de prélèvement';
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case 'PERMISSION_TO_CONSULT_THE_RISK_CENTER':
                                                                keyFr =
                                                                    'Autorisation de consultation de la centrale des riques';
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case 'REQUEST_FOR_FINANCING':
                                                                keyFr = 'Demande de financement';
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case 'ACCOUNT_OPENING_REQUEST':
                                                                keyFr = 'Demande ouverture de compte';
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case 'COMMITMENT_TO_MAINTAIN_SUFFICIENT_BALANCE_UNDER_CCP_ACCOUNT':
                                                                this.noDucmentMissing = false;
                                                                keyFr =
                                                                    'Engagement de maintien solde suffisant sous compte CCP';
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case 'TERMINATION_CERTIFICATE':
                                                                keyFr = 'Attestation Radiation';
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case 'CORP_PRESENCE':
                                                                keyFr = 'Présence au Corp';
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case 'INCOME_CERTIFICATE':
                                                                keyFr = 'Attestation de Revenue';
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case 'INCOME_CERTIFICATE_MILITARY':
                                                                keyFr = 'Attestation de Revenue';
                                                                this.noDucmentMissing = false;
                                                                break;
                                                            case 'RENA':
                                                                keyFr = 'RENA';
                                                                this.noDucmentMissing = false;
                                                                break;
                                                        }

                                                    });

                                                    // Popup erreur 1
                                                    if (!this.noDucmentMissing) {
                                                        const msg = `Le document ${keyFr} est manquant `;
                                                        this.EkSnackBar.openSnackBar(
                                                            msg,
                                                            '',
                                                            'error-snackbar'
                                                        );
                                                        this.openErrorPopup();
                                                    }

                                                    if (this.noDucmentMissing) {
                                                        // Puting the documents in the subject
                                                        this.documentService
                                                            .getDocumentsByFolderId(this.idFolder)
                                                            .subscribe((res: any) => {
                                                                if (res) {
                                                                    this.folderVerificationService.documents$.next(res.body);
                                                                }
                                                            });

                                                        // verify documents
                                                        this.oneByOneValidations =
                                                            this.folderVerificationService.verifyDocuments(
                                                                res.body
                                                            );
                                                        this.folderVerificationService.getMessages(res.body);
                                                        this.needSomeChanges = this.folderVerificationService.needSomeChanges;

                                                        this.foldersService.isCheckSbj$.next(true);

                                                        this.Subs.push(
                                                            this.documentService
                                                                .getClientInfo(this.idFolder)
                                                                .subscribe({
                                                                    next: (res) => {
                                                                        this.loading = false;
                                                                        this.ocrInfos = res.body;
                                                                        this.ocrInfos.phone =
                                                                            this.orderSplit?.phone;

                                                                        this.documentService.ocrSubject.next(
                                                                            res.body
                                                                        );
                                                                        this.step = nextStep;
                                                                        this.router.navigate([], {
                                                                            queryParams: {step: nextStep},
                                                                        });
                                                                        this.cdRef.detectChanges();
                                                                        this.dialog.closeAll();
                                                                        if (
                                                                            !this.areAllChecked(
                                                                                this.oneByOneValidations
                                                                            )
                                                                        ) {
                                                                            this.needSomeChanges = this.folderVerificationService.needSomeChanges;
                                                                            // this.openErrorPopup()
                                                                            if (this.needSomeChanges) {
                                                                                this.openMessagesPopup();
                                                                            }
                                                                        }
                                                                    },
                                                                    error: (error) => {
                                                                        this.loading = false;
                                                                        const msg = 'Veuillez reéssayer à nouveau';
                                                                        this.EkSnackBar.openSnackBar(
                                                                            msg,
                                                                            'ok',
                                                                            'error-snackbar'
                                                                        );
                                                                        this.dialog.closeAll();
                                                                    },
                                                                })
                                                        );
                                                    }
                                                }
                                            },
                                            error: () => {
                                                this.dialog.closeAll();
                                                const msg =
                                                    'Connexion avec le serveur a échoué, veuillez réessayer à nouveau';
                                                this.EkSnackBar.openSnackBar(
                                                    msg,
                                                    'ok',
                                                    'error-snackbar'
                                                );
                                            },
                                        })
                                );
                            } else {
                                this.dialog.open(SecondStepConfirmationPopupComponent, {
                                    width: '480px',
                                });
                                this.documentService.getClientInfo(this.idFolder).subscribe({
                                    next: (res) => {
                                        this.loading = false;
                                        this.ocrInfos = res.body;
                                        this.ocrInfos.phone = this.orderSplit?.phone;

                                        this.documentService.ocrSubject.next(res.body);
                                        this.dialog.closeAll();
                                        this.step = nextStep;
                                        this.router.navigate([], {
                                            queryParams: {step: nextStep},
                                        });
                                        this.cdRef.detectChanges();
                                    },
                                    error: (error) => {
                                        this.loading = false;
                                        const msg = 'Veuillez reéssayer à nouveau';
                                        this.EkSnackBar.openSnackBar(msg, 'ok', 'error-snackbar');
                                        this.dialog.closeAll();
                                    },
                                });
                            }
                        }
                    });
                }
                break;
            }
            case 4:

                // if POS has not the permission to update client info ...
                if (((this.currentRole === 'ROLE_POS_EK' || this.currentRole === 'ROLE_COMMERCIAL_POS_EK') && this.folder.folderState !== 'EK_FOLDER_CREATED' && this.folder.folderState !== 'EK_FOLDER_RESERVE')) {
                    this.step = 4;
                    this.router.navigate([], {queryParams: {step: this.step}});
                    return;
                }

                // if it is banker or credit with folder state different from EK_FOLDER_IN_PROGRESS and EK_BANK_RESERVE go directly to step 4
                if (
                    this.currentRole === 'ROLE_BANKER_EK' ||
                    (this.currentRole === 'ROLE_CREDIT_ANALYST_EK' && this.folder.folderState !== 'EK_FOLDER_IN_PROGRESS' && this.folder.folderState !== 'EK_BANK_RESERVE')
                ) {

                    this.step = 4;
                    this.router.navigate([], {queryParams: {step: this.step}});
                } else {
                    if (this.ocrInfosForm && this.ocrInfosForm.invalid) {

                        this.getFormValidationErrors(this.ocrInfosForm);

                        Object.keys(this.ocrInfosForm.controls).forEach((controlName) =>
                            this.ocrInfosForm.controls[controlName].markAsTouched()
                        );

                        const message = `vous devez remplir tous les champs obligatoires !`;
                        this.layoutUtilsService.showActionNotification(
                            message,
                            MessageType.Update,
                            5000,
                            true,
                            true
                        );

                        return;
                    } else {
                        this.updateClientInformationStep3();
                    }
                }
                break;
            case 5: {
                if (this.step > nextStep) {
                    this.step = nextStep;
                    this.router.navigate([], {queryParams: {step: nextStep}});
                    this.cdRef.detectChanges();

                    // if ((this.currentRole === 'ROLE_POS_EK' && (this.folder.folderState !== 'EK_FOLDER_CREATED' && this.folder.folderState !== 'EK_FOLDER_RESERVE'))
                    //     || (this.currentRole === 'ROLE_CREDIT_ANALYST_EK' && (this.folder.folderState !== 'EK_BANK_RESERVE' && this.folder.folderState !== 'EK_FOLDER_IN_PROGRESS'))) {

                    //     // click on previous button
                    //     if (previous) {
                    //         this.step = nextStep;
                    //         this.router.navigate([], {queryParams: {step: nextStep}});
                    //         return;
                    //     }

                    //     // navigate to 6 step directly and skip 5 step
                    //     this.step = 6;
                    //     this.router.navigate([], {queryParams: {step: 6}});

                    // } else {
                    //     // navigate to step of scoring and simulation
                    //     if (this.currentRole !== 'ROLE_POS_EK' && !previous) {
                    //         this.step = 5;
                    //         this.router.navigate([], {queryParams: {step: 5}});

                    //     } else {// skip step
                    //         this.step = nextStep;
                    //         this.router.navigate([], {queryParams: {step: nextStep}});
                    //     }
                    // }

                } else {
                    this.documentService.checkBankDocuments(this.idFolder).subscribe({
                        next: (res: any) => {
                            if (
                                String(res.body).includes('The BANK documents are not uploaded')
                            ) {
                                this.documentService.isBankDocCheckedSubject.next(false);
                                const msg = 'Veuillez ajouter tous les documents';
                                this.EkSnackBar.openSnackBar(msg, 'ok', 'error-snackbar');
                            } else {
                                this.documentService.isBankDocCheckedSubject.next(true);
                                this.step = nextStep;
                                this.router.navigate([], {queryParams: {step: nextStep}});
                            }
                        },
                        error: (error: any) => {
                            this.documentService.isBankDocCheckedSubject.next(false);
                            const msg = 'Connexion avec le serveur a échoué';
                            this.EkSnackBar.openSnackBar(msg, 'ok', 'error-snackbar');
                        },
                    });
                }
                this.cdRef.detectChanges();
                break;
            }
            case 6:
                this.router.navigate([], {queryParams: {step: nextStep}});
                this.cdRef.detectChanges();
                break;
            case 7:
                this.router.navigate([], {queryParams: {step: nextStep}});
                this.cdRef.detectChanges();
                break;
            case 8:
                // Etape précédente
                if (this.step > nextStep) {
                    this.router.navigate([], {queryParams: {step: nextStep}});
                    this.cdRef.detectChanges();
                } else {
                    // Analyste crédit
                    if ((this.currentRole === 'ROLE_POS_EK' || this.currentRole === 'ROLE_COMMERCIAL_POS_EK') && !this.canDownload) {
                        if (this.folderShipping) {
                            if (this.isEditable) {
                                this.foldersService
                                    .updatefileShippingType(
                                        this.orderSplit.folderId,
                                        this.folderShipping
                                    )
                                    .pipe(finalize(() => (this.validationLoading = false)))
                                    .subscribe((res) => {
                                        if (res.body) {
                                            this.foldersService.shippingSbj$.next(res.body);
                                            // this.router.navigate([], {queryParams: {step: nextStep}});
                                            this.snackBar.open('adresse modifiée avec succés', 'ok', {
                                                panelClass: 'error-snackbar',
                                            });
                                            this.cdRef.detectChanges();
                                        } else {
                                            this.snackBar.open('vérifier l\'adresse saisie', 'ok', {
                                                panelClass: 'error-snackbar',
                                            });
                                        }
                                    });
                            } else {
                                this.validationLoading = true;
                                if (this.isValid) {
                                    this.foldersService
                                        .sendfileShippingType(this.folderShipping)
                                        .pipe(finalize(() => (this.validationLoading = false)))
                                        .subscribe((res) => {
                                            if (res.body) {
                                                this.foldersService.shippingSbj$.next(res.body);

                                                // this.openPopUP();
                                                // this.router.navigate([], {
                                                //     queryParams: {step: nextStep},
                                                // });
                                                this.snackBar.open('adresse crée avec succés', 'ok', {
                                                    panelClass: 'error-snackbar',
                                                });
                                            } else {
                                                this.snackBar.open('vérifier l\'adresse saisie', 'ok', {
                                                    panelClass: 'error-snackbar',
                                                });
                                            }
                                            this.cdRef.detectChanges();
                                        });
                                }
                            }
                        } else {
                            console.log('can not find shipping address ...!');
                        }
                    } else if ((this.currentRole === 'ROLE_POS_EK' || this.currentRole === 'ROLE_COMMERCIAL_POS_EK') && this.canDownload) {
                        this.guaranteesChecked = false;
                        this.invoicesChecked = false;
                        // Checking Guarantees first
                        this.documentService
                            .checkGuaranteesDocuments(this.idFolder)
                            .subscribe({
                                next: (res: any) => {
                                    if (
                                        String(res.body).includes(
                                            'The GUARANTEES documents are not uploaded'
                                        )
                                    ) {
                                        this.guaranteesChecked = false;
                                        const msg = 'Veuillez ajouter tous les documents';
                                        this.EkSnackBar.openSnackBar(msg, 'ok', 'error-snackbar');
                                    } else {
                                        // this.step = nextStep;
                                        // this.router.navigate([], { queryParams: { step: nextStep } });
                                        // this.cdRef.detectChanges();
                                        this.guaranteesChecked = true;
                                        if (this.invoicesChecked && this.guaranteesChecked) {
                                            this.step = nextStep;
                                            this.router.navigate([], {queryParams: {step: nextStep}});
                                            this.cdRef.detectChanges();
                                        }
                                    }
                                },
                                error: (error: any) => {
                                    const msg = 'Connexion avec le serveur a échoué';
                                    this.EkSnackBar.openSnackBar(msg, 'ok', 'error-snackbar');
                                },
                            });
                        // Checking Invoices
                        this.documentService.checkInvoiceDocuments(this.idFolder).subscribe({
                            next: (res: any) => {
                                if (
                                    String(res.body).includes(
                                        'The INVOICE documents are not uploaded'
                                    )
                                ) {
                                    this.invoicesChecked = false;
                                    const msg = 'Veuillez ajouter tous les documents';
                                    this.EkSnackBar.openSnackBar(msg, 'ok', 'error-snackbar');
                                } else {
                                    this.invoicesChecked = true;
                                    if (this.invoicesChecked && this.guaranteesChecked) {
                                        this.step = nextStep;
                                        this.router.navigate([], {queryParams: {step: nextStep}});
                                        this.cdRef.detectChanges();
                                    }
                                }
                            },
                            error: (error: any) => {
                                const msg = 'Connexion avec le serveur a échoué';
                                this.EkSnackBar.openSnackBar(msg, 'ok', 'error-snackbar');
                            },
                        });
                    } else if (this.currentRole !== 'ROLE_POS_EK' && this.currentRole === 'ROLE_COMMERCIAL_POS_EK') {
                        this.router.navigate([], {queryParams: {step: nextStep}});
                    }
                    this.cdRef.detectChanges();
                }
                break;

            case 9:
                if ((this.currentRole === 'ROLE_POS_EK' || this.currentRole === 'ROLE_COMMERCIAL_POS_EK') && this.canDownload) {
                    if (this.folderShipping) {
                        if (this.isEditable) {
                            this.foldersService
                                .updatefileShippingType(
                                    this.orderSplit.folderId,
                                    this.folderShipping
                                )
                                .pipe(finalize(() => (this.validationLoading = false)))
                                .subscribe((res) => {
                                    if (res.body) {
                                        this.foldersService.shippingSbj$.next(res.body);
                                        // this.router.navigate([], {queryParams: {step: nextStep}});
                                        this.snackBar.open('adresse modifiée avec succés', 'ok', {
                                            panelClass: 'error-snackbar',
                                        });
                                        this.cdRef.detectChanges();
                                    } else {
                                        this.snackBar.open('vérifier l\'adresse saisie', 'ok', {
                                            panelClass: 'error-snackbar',
                                        });
                                    }
                                });
                        } else {
                            this.validationLoading = true;
                            if (this.isValid) {
                                this.foldersService
                                    .sendfileShippingType(this.folderShipping)
                                    .pipe(finalize(() => (this.validationLoading = false)))
                                    .subscribe((res) => {
                                        if (res.body) {
                                            this.foldersService.shippingSbj$.next(res.body);

                                            // this.openPopUP();
                                            // this.router.navigate([], {
                                            //     queryParams: {step: nextStep},
                                            // });
                                            this.snackBar.open('adresse crée avec succés', 'ok', {
                                                panelClass: 'error-snackbar',
                                            });
                                        } else {
                                            this.snackBar.open('vérifier l\'adresse saisie', 'ok', {
                                                panelClass: 'error-snackbar',
                                            });
                                        }
                                        this.cdRef.detectChanges();
                                    });
                            }
                        }
                    } else {
                        console.log('can not find shipping address ...!');
                    }
                } else if (this.currentRole !== 'ROLE_POS_EK' && this.currentRole !== 'ROLE_COMMERCIAL_POS_EK') {
                    if (!this.canDownload && (this.currentRole === 'ROLE_BANKER_EK' || this.currentRole === 'ROLE_CREDIT_ANALYST_EK')) {
                        if (this.folderShipping) {
                            if (this.isEditable) {
                                this.foldersService
                                    .updatefileShippingType(
                                        this.orderSplit.folderId,
                                        this.folderShipping
                                    )
                                    .pipe(finalize(() => (this.validationLoading = false)))
                                    .subscribe((res) => {
                                        if (res.body) {
                                            this.foldersService.shippingSbj$.next(res.body);
                                            // this.router.navigate([], {queryParams: {step: nextStep}});
                                            this.snackBar.open('adresse modifiée avec succés', 'ok', {
                                                panelClass: 'error-snackbar',
                                            });
                                            this.cdRef.detectChanges();
                                        } else {
                                            this.snackBar.open('vérifier l\'adresse saisie', 'ok', {
                                                panelClass: 'error-snackbar',
                                            });
                                        }
                                    });
                            } else {
                                this.validationLoading = true;
                                if (this.isValid) {
                                    this.foldersService
                                        .sendfileShippingType(this.folderShipping)
                                        .pipe(finalize(() => (this.validationLoading = false)))
                                        .subscribe((res) => {
                                            if (res.body) {
                                                this.foldersService.shippingSbj$.next(res.body);

                                                // this.openPopUP();
                                                // this.router.navigate([], {
                                                //     queryParams: {step: nextStep},
                                                // });
                                                this.snackBar.open('adresse crée avec succés', 'ok', {
                                                    panelClass: 'error-snackbar',
                                                });
                                            } else {
                                                this.snackBar.open('vérifier l\'adresse saisie', 'ok', {
                                                    panelClass: 'error-snackbar',
                                                });
                                            }
                                            this.cdRef.detectChanges();
                                        });
                                }
                            }
                        } else {
                            console.log('can not find shipping address ...!');
                        }
                    } else {
                        this.guaranteesChecked = false;
                        this.invoicesChecked = false;
                        if (this.step > nextStep) {
                            this.step = nextStep;
                            this.router.navigate([], {queryParams: {step: nextStep}});
                        } else {
                            // Checking Guarantees first
                            this.documentService
                                .checkGuaranteesDocuments(this.idFolder)
                                .subscribe({
                                    next: (res: any) => {
                                        if (
                                            String(res.body).includes(
                                                'The GUARANTEES documents are not uploaded'
                                            )
                                        ) {
                                            this.guaranteesChecked = false;
                                            const msg = 'Veuillez ajouter tous les documents';
                                            this.EkSnackBar.openSnackBar(msg, 'ok', 'error-snackbar');
                                        } else {
                                            // this.step = nextStep;
                                            // this.router.navigate([], { queryParams: { step: nextStep } });
                                            // this.cdRef.detectChanges();
                                            this.guaranteesChecked = true;
                                            if (this.invoicesChecked && this.guaranteesChecked) {
                                                this.step = nextStep;
                                                this.router.navigate([], {queryParams: {step: nextStep}});
                                                this.cdRef.detectChanges();
                                            }
                                        }
                                    },
                                    error: (error: any) => {
                                        const msg = 'Connexion avec le serveur a échoué';
                                        this.EkSnackBar.openSnackBar(msg, 'ok', 'error-snackbar');
                                    },
                                });
                            // Checking Invoices
                            this.documentService.checkInvoiceDocuments(this.idFolder).subscribe({
                                next: (res: any) => {
                                    if (
                                        String(res.body).includes(
                                            'The INVOICE documents are not uploaded'
                                        )
                                    ) {
                                        this.invoicesChecked = false;
                                        const msg = 'Veuillez ajouter tous les documents';
                                        this.EkSnackBar.openSnackBar(msg, 'ok', 'error-snackbar');
                                    } else {
                                        this.invoicesChecked = true;
                                        if (this.invoicesChecked && this.guaranteesChecked) {
                                            this.step = nextStep;
                                            this.router.navigate([], {queryParams: {step: nextStep}});
                                            this.cdRef.detectChanges();
                                        }
                                    }
                                },
                                error: (error: any) => {
                                    const msg = 'Connexion avec le serveur a échoué';
                                    this.EkSnackBar.openSnackBar(msg, 'ok', 'error-snackbar');
                                },
                            });
                        }
                    }
                    this.cdRef.detectChanges();
                }

                break;
            case 10:
                if (this.folderShipping) {
                    if (this.isEditable) {
                        this.foldersService
                            .updatefileShippingType(
                                this.orderSplit.folderId,
                                this.folderShipping
                            )
                            .pipe(finalize(() => (this.validationLoading = false)))
                            .subscribe((res) => {
                                if (res.body) {
                                    this.foldersService.shippingSbj$.next(res.body);
                                    // this.router.navigate([], {queryParams: {step: nextStep}});
                                    this.snackBar.open('adresse modifiée avec succés', 'ok', {
                                        panelClass: 'error-snackbar',
                                    });
                                    this.cdRef.detectChanges();
                                } else {
                                    this.snackBar.open('vérifier l\'adresse saisie', 'ok', {
                                        panelClass: 'error-snackbar',
                                    });
                                }
                            });
                    } else {
                        this.validationLoading = true;
                        if (this.isValid) {
                            this.foldersService
                                .sendfileShippingType(this.folderShipping)
                                .pipe(finalize(() => (this.validationLoading = false)))
                                .subscribe((res) => {
                                    if (res.body) {
                                        this.foldersService.shippingSbj$.next(res.body);

                                        // this.openPopUP();
                                        // this.router.navigate([], {
                                        //     queryParams: {step: nextStep},
                                        // });
                                        this.snackBar.open('adresse crée avec succés', 'ok', {
                                            panelClass: 'error-snackbar',
                                        });
                                    } else {
                                        this.snackBar.open('vérifier l\'adresse saisie', 'ok', {
                                            panelClass: 'error-snackbar',
                                        });
                                    }
                                    this.cdRef.detectChanges();
                                });
                        }
                    }
                } else {
                    console.log('can not find shipping address ...!');
                }
                break;
        }
    }

    // If product didn't find in store
    loadFolderByIdService(folderID) {
        this.foldersService.getFolderById(folderID).subscribe((res) => {
            if (res) {
                this.foldersService.folderSbj$.next(res);
                this.foldersService.selectedFolderSubject$.next(res);
                this.folder = res;
            }
        });
    }

    // If product didn't find in store
    loadOrderSplitByIdService(orderID) {
        this.orderSplitService.getOrderSplitById(orderID).subscribe((res) => {
            if (res) {
                this.orderSplit = res;
                this.selectedOrderSubject.next(res);
                this.orderSplitService.selectedOrderSubject.next(res);
                this.loadClient(res.clientId);
                this.loadFolderByIdService(this.idFolder);
            }
        });
    }

    loadClient(idClient) {
        this.clientsService.getById(idClient).subscribe((res) => {
            if (res) {
                this._myData$.next(res);
                this.client = res;
                this.client?.socialClientCategory
                    ? (this.personnalSituation = this.client?.socialClientCategory)
                    : '';
                this.client?.professionalClientCategory
                    ? (this.profSituation = this.client?.professionalClientCategory)
                    : '';
            }
        });
    }

    createOrderSplit() {
        // ROLE_BANKER_EK and ROLE_CREDIT_ANALYST_EK can not create order split
        if (this.currentRole === 'ROLE_BANKER_EK' || this.currentRole === 'ROLE_CREDIT_ANALYST_EK') {
            return;
        }


        if (this.simulationForm && this.simulationForm.invalid) {
            Object.keys(this.simulationForm.controls).forEach((controlName) =>
                this.simulationForm.controls[controlName].markAsTouched()
            );

            const message = `vous devez remplir tous les champs obligatoires !`;
            this.layoutUtilsService.showActionNotification(
                message,
                MessageType.Update,
                5000,
                true,
                true
            );

            return;
        }


        if (this.orderCreationEntity.configurationsDetails && this.orderCreationEntity.configurationsDetails.length > 0) {
            if (this.cartMessage.isLimited === true) {
                this.EkSnackBar.openSnackBar(
                    this.cartMessage.messages,
                    'ok',
                    'error-snackbar'
                );
            } else {
   /*             console.log('base URL admin...', this.document.baseURI);
                if (this.document.baseURI.includes('https://alsalamalgeria.admin.ekiclik.dz') && (this.currentRole === 'ROLE_POS_EK' || this.currentRole === 'ROLE_COMMERCIAL_POS_EK')){
                    // if production env, check phone number before order creation
                    this.checkPhoneNumber(this.simulationForm.controls.phoneNumber.value);
                } else {
                    this.createNewOrderSplit();
                }*/
                this.createNewOrderSplit();
            }
        } else {
            const message = `Veuillez ajouter des produits !`;
            this.layoutUtilsService.showActionNotification(
                message,
                MessageType.Delete,
                5000,
                true,
                true
            );
        }
    }

    setCartCapacity(event) {
        this.cartCapacity = event;
        localStorage.setItem('cartCapacity', JSON.stringify(this.cartCapacity));
    }


    updateClient() {

        this.client.firstname = this.simulationForm.controls.firstName.value;
        this.client.lastname = this.simulationForm.controls.lastName.value;

        this.clientsService.update(this.client).subscribe();

        this._myData$.next(this.client);

    }

    updateOrderSplitAdmin() {

        if (((this.currentRole === 'ROLE_POS_EK' || this.currentRole === 'ROLE_COMMERCIAL_POS_EK') && this.folder.folderState !== 'EK_FOLDER_CREATED' && this.folder.folderState !== 'EK_FOLDER_RESERVE')
            || (this.currentRole === 'ROLE_CREDIT_ANALYST_EK' && this.folder.folderState !== 'EK_BANK_RESERVE' && this.folder.folderState !== 'EK_FOLDER_IN_PROGRESS')) {
            this.step = 2;
            this.router.navigate([], {queryParams: {step: 2}});
            return;
        } else {
            // if any field has been modified
            if (
                this.simulationForm &&
                this.simulationForm.touched &&
                this.simulationForm.dirty
            ) {

                // remove space of decimal pipe to avoid form validation error
                const salaryValue = this.simulationForm.get('salary').value.toString().replace(' ', '');
                this.simulationForm.get('salary').setValue(salaryValue);

                if (this.simulationForm.valid) {
                    if (this.cartMessage.isLimited === true) {
                        this.EkSnackBar.openSnackBar(
                            this.cartMessage.messages,
                            'ok',
                            'error-snackbar'
                        );
                    } else {
                        this.loadingSubject$.next(true);

                        const OrderSplit = this.prepareOrderSplit();

                        const updatedOrderSplit: Update<OrderSplitUpdateAdmin> = {
                            id: OrderSplit.id,
                            changes: OrderSplit,
                        };

                        this.store.dispatch(
                            OrderSplitUpdated({
                                idClient: this.orderSplit.clientId,
                                idOrderSplit: this.idOrder,
                                OrderSplit,
                                partialOrderSplit: updatedOrderSplit,
                            })
                        );

                        // order updated failed
                        this._actions$
                            .pipe(ofType(OrderSplitUpdateFailed))
                            .subscribe((data: any) => {

                                if (data && data.error.status === 403) {
                                    const message = `you can not update FOLDER , Acces Denied!`;
                                    this.layoutUtilsService.showActionNotification(
                                        message,
                                        MessageType.Update,
                                        5000,
                                        true,
                                        true
                                    );
                                } else {
                                    const message = `there is an error in updating folder!`;
                                    this.layoutUtilsService.showActionNotification(
                                        message,
                                        MessageType.Update,
                                        5000,
                                        true,
                                        true
                                    );
                                }

                                // stop spinner
                                this.loadingSubject$.next(false);

                            });

                        // order updated successfully
                        this._actions$
                            .pipe(ofType(OrderSplitUpdatedSuccessfully))
                            .subscribe((data: any) => {

                                // update social info of client after update of order split
                                this.setSocialInfo(
                                    this.idFolder,
                                    'OTHER',
                                    this.simulationForm.controls.profSit.value,
                                    this.simulationForm.controls.persoSit.value
                                );

                                // update client first and last name
                                this.updateClient();

                                const message = `order Split successfully has been updated.`;
                                this.layoutUtilsService.showActionNotification(
                                    message,
                                    MessageType.Update,
                                    5000,
                                    true,
                                    true
                                );
                                this.loadingSubject$.next(false);
                                this.step = 2;
                                this.router.navigate([], {queryParams: {step: 2}});
                                this.refresh();
                            });
                    }
                } else {
                    Object.keys(this.simulationForm.controls).forEach((controlName) =>
                        this.simulationForm.controls[controlName].markAsTouched()
                    );

                    const message = `there is error in some fields!`;
                    this.layoutUtilsService.showActionNotification(
                        message,
                        MessageType.Update,
                        5000,
                        true,
                        true
                    );
                }
            } else {
                this.step = 2;
                this.router.navigate([], {queryParams: {step: 2}});
            }
        }

    }

    navigateToListing() {
        this.router.navigate(['ek-ecommerce/folders']);
    }

    setSocialInfo(
        id,
        contractType,
        professionalClientCategory,
        socialClientCategory
    ) {
        this.clientOcrInformationService
            .changeSocialProfessionalInformation(
                id,
                contractType,
                professionalClientCategory,
                socialClientCategory
            )
            .subscribe();
    }

    prepareOrderSplit(): any {

        // convert date of birth to dd-MM-yyyy format
        const tempDate = this.datePipe.transform(this.orderCreationEntity.dateOfBirthSimulation, 'dd-MM-yyyy');

        const orderSplitUpdateAdmin = {
            dateOfBirthSimulation: tempDate,
            id: this.orderSplit.id,
            numberOfMonths: this.orderCreationEntity.numberOfMonths,
            phone: this.orderCreationEntity.phone,
            pointOfSaleId: this.selectedRelay
                ? this.selectedRelay.id
                : this.orderSplit.pointOfSaleTo.id,
            professionalClientCategory:
            this.orderCreationEntity.professionalClientCategory,

            salarySimulation: this.orderCreationEntity.salarySimulation,
            socialClientCategory: this.orderCreationEntity.socialClientCategory,
            email: this.orderCreationEntity.email,
        };

        return orderSplitUpdateAdmin;
    }

    refresh() {
        this.loadingSubject$.next(false);

        this.orderSplitService.getById(this.orderSplit.id).subscribe((res) => {
            res.orderItems = res.orderItems.sort(
                (a, b) => a.orderProductId - b.orderProductId
            );
            this.orderSplit = res;
            this.selectedOrderSubject.next(res);
            this.orderSplitService.selectedOrderSubject.next(res);
        });

        this.clientsService.getById(this.orderSplit.clientId).subscribe((res) => {
            this._myData$.next(res);
        });
    }

    setClientInfo(event) {
        // update Client info
        this.ocrInfosForm = event;
    }

    areAllChecked(object: any): boolean {
        if (object) {
            return Object.values(object).every((item) => item === true);
        } else {
            return false;
        }
    }

    openErrorPopup() {
        const dialogRef = this.dialog.open(FilesSecondErrorPopupComponent, {
            height: '569px',
            width: '428px',
        });
    }

    openMessagesPopup() {
        const routeInfo = {
            orderId: this.idOrder,
            folderId: this.idFolder
        };
        this.dialog.open(DocumentsMessagesPopupComponent, {
            height: 'fit-content',
            width: '700px',
            maxHeight: '550px',
            panelClass: 'ocr-messages-popup',
            data: routeInfo
        });
    }

    editScoring() {
        this.foldersService.downloadScoringFile(this.idFolder)
            .subscribe({
                next: (response) => {
                    if (response.message === 'PDF Scoring successfully generated !' && response.data) {
                        this.loadingScoringFormSubject$.next(true);
                        const byteCharacters = atob(response.data);
                        const byteNumbers = new Array(byteCharacters.length);
                        for (let i = 0; i < byteCharacters.length; i++) {
                            byteNumbers [i] = byteCharacters.charCodeAt(i);
                        }
                        const byteArray = new Uint8Array(byteNumbers);
                        const nav = (window.navigator as any);
                        const blob = new Blob([byteArray], {type: 'application / vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                        if (nav && nav.msSaveOrOpenBlob) {
                            nav.msSaveOrOpenBlob(blob);
                            return;
                        }
                        const url = window.URL.createObjectURL(blob);
                        const anchor = document.createElement('a');
                        anchor.download = `fiche_scoring` + '_' + this.folder.requestNumber + '.pdf';
                        anchor.href = url;

                        anchor.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
                        this.loadingScoringFormSubject$.next(false);
                        setTimeout(function () {
                            window.URL.revokeObjectURL(url);
                            anchor.remove();
                        }, 100);
                    }

                },
                error: (error) => {

                    if (error.error.message) {

                        const message = this.scoringExceptionsInterpretPipe.transform(error.error.message);
                        this.layoutUtilsService.showActionNotification(
                            message,
                            MessageType.Update,
                            5000,
                            true,
                            true
                        );
                    } else {
                        const message = 'Erreur lors du téléchargement du fichier de scoring ! Veuillez réessayer.';
                        this.layoutUtilsService.showActionNotification(
                            message,
                            MessageType.Update,
                            5000,
                            true,
                            true
                        );
                    }

                    this.loadingScoringFormSubject$.next(false);
                }
            });
    }

    downloadScoringSimulation() {
        this.orderSplitService.downloadScoringSimulation(this.idFolder)
            .subscribe({
                next: (response) => {
                    if (response.message === 'success' && response.body) {
                        this.loadingSimulationSubject$.next(true);
                        const byteCharacters = atob(response.body);
                        const byteNumbers = new Array(byteCharacters.length);
                        for (let i = 0; i < byteCharacters.length; i++) {
                            byteNumbers [i] = byteCharacters.charCodeAt(i);
                        }
                        const byteArray = new Uint8Array(byteNumbers);
                        const nav = (window.navigator as any);
                        const blob = new Blob([byteArray], {type: 'application / vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                        if (nav && nav.msSaveOrOpenBlob) {
                            nav.msSaveOrOpenBlob(blob);
                            return;
                        }
                        const url = window.URL.createObjectURL(blob);
                        const anchor = document.createElement('a');
                        anchor.download = `fiche_simulation` + '_' + this.folder.requestNumber + '.pdf';
                        anchor.href = url;

                        anchor.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
                        this.loadingSimulationSubject$.next(false);
                        setTimeout(function () {
                            window.URL.revokeObjectURL(url);
                            anchor.remove();
                        }, 100);
                    }

                },
                error: (error) => {
                    const message = 'Error in downloading fiche de simulation! Please try again.';
                    this.layoutUtilsService.showActionNotification(
                        message,
                        MessageType.Update,
                        5000,
                        true,
                        true
                    );
                    this.loadingSimulationSubject$.next(false);
                }
            });
    }

    downloadRecapInformation() {
        this.clientOcrInformationService.downloadRecapInformationsText(this.idFolder);
        /* .subscribe({
           next: (response) => {
             if (response.message === "success" && response.body) {
               const jsonContent = atob(response.body);
               const blob = new Blob([jsonContent], {
                 type:"text/csv;charset=utf-8;"
               });
               const url = window.URL.createObjectURL(blob);
               const anchor = document.createElement("a");
               anchor.download = "recap_information.csv";
               anchor.href = url;

               anchor.dispatchEvent(
                   new MouseEvent("click", {
                     bubbles: true,
                     cancelable: true,
                     view: window
                   })
               );
               setTimeout(function() {
                 window.URL.revokeObjectURL(url);
                 anchor.remove();
               }, 100);
             }

             this.loadingRecapSubject.next(false);
           },
           error: (error) => {
             const message = "Error in downloading recap information! Please try again.";
             this.layoutUtilsService.showActionNotification(
                 message,
                 MessageType.Update,
                 5000,
                 true,
                 true
             );
             this.loadingRecapSubject.next(false);
           }
         });*/
    }


    updateClientInformationStep3() {

        if (this.ocrInfosForm && this.ocrInfosForm.touched) {
            // update Client
            this.client.socialClientCategory =
                this.ocrInfosForm.controls.socialClientCategory.value;
            this.client.professionalClientCategory =
                this.ocrInfosForm.controls.professionalClientCategory.value;
            this.clientsService
                .update(this.client)
                .subscribe();

            // update SocialInfo
            this.setSocialInfo(
                this.idFolder,
                this.ocrInfosForm.controls.contractType.value,
                this.ocrInfosForm.controls.professionalClientCategory.value,
                this.ocrInfosForm.controls.socialClientCategory.value
            );

            // update Client Info
            const clientInfoUpdated: ocrInfo = {
                id: this.ocrInfos.id,
                folderId: this.idFolder,
                createdAt: this.ocrInfos.createdAt,
                isInfoCompleted: true,
                updatedAt: this.ocrInfos.updatedAt,
                personnelSituation: {
                    personnelSituationAddress: this.ocrInfosForm.controls.address.value ? this.ocrInfosForm.controls.address.value : '',
                    personnelSituationAddressAscii: this.ocrInfosForm.controls.personnelSituationAddressAscii.value ? this.ocrInfosForm.controls.personnelSituationAddressAscii.value : '',
                    personnelSituationCommune: this.ocrInfosForm.controls.commune.value,
                    dependentChild: this.ocrInfosForm.controls.dependentChild.value ? this.ocrInfosForm.controls.dependentChild.value : '',
                    dependentOtherPeople:
                    this.ocrInfosForm.controls.dependentOtherPeople.value,
                    educationalLevel: this.ocrInfosForm.controls.educationalLevel.value,
                    familyState: this.ocrInfosForm.controls.socialClientCategory.value,
                    habitation: this.ocrInfosForm.controls.habitation.value,
                    zipCode: this.ocrInfosForm.controls.zipCode.value,
                },
                personnelInformation: {
                    actOfBrithNumber: this.ocrInfosForm.controls.actOfBrithNumber.value,
                    birthDay: this.datePipe.transform(this.ocrInfosForm.controls.birthDay.value, 'dd-MM-yyyy'),
                    cardType: this.ocrInfosForm.controls.cardType.value,
                    commune: this.ocrInfosForm.controls.commune.value,
                    communeCode: this.ocrInfosForm.controls.communeCode.value,
                    deliveryDaira: this.ocrInfosForm.controls.deliveryDaira.value,
                    firstNameFather: this.ocrInfosForm.controls.fatherFirstName.value ? this.ocrInfosForm.controls.fatherFirstName.value : '',
                    firstNameMother: this.ocrInfosForm.controls.motherFirstName.value ? this.ocrInfosForm.controls.motherFirstName.value : '',
                    lastNameMother: this.ocrInfosForm.controls.motherLastName.value ? this.ocrInfosForm.controls.motherLastName.value : '',
                    // deliveryDate: this.ocrInfosForm.controls.deliveryDate.value,
                    deliveryDate: this.datePipe.transform(this.ocrInfosForm.controls.deliveryDate.value, 'dd-MM-yyyy'),
                    firstName: this.ocrInfosForm.controls.firstName.value,
                    daira: this.ocrInfosForm.controls.daira.value,
                    codePlaceOfBirth: this.ocrInfosForm.controls.codePlaceOfBirth.value,
                    fistNameEnArab: this.ocrInfosForm.controls.fistNameEnArab.value,
                    idCardNumber: this.ocrInfosForm.controls.idCardNumber.value,
                    lastName: this.ocrInfosForm.controls.lastName.value,
                    birthWilaya: this.ocrInfosForm.controls.birthWilaya.value,
                    lastNameEnArab: this.ocrInfosForm.controls.lastNameEnArab.value,
                    nationalIdNumber: this.ocrInfosForm.controls.nationalIdNumber.value,
                    placeOfBrith: this.ocrInfosForm.controls.placeOfBrith.value,
                    placeOfBrithArabic: this.ocrInfosForm.controls.placeOfBrithArabic.value,
                    socialSecurityNumber:
                    this.ocrInfosForm.controls.socialSecurityNumber.value,
                    // validationDate: this.ocrInfosForm.controls.validationDate.value,
                    validationDate: this.datePipe.transform(this.ocrInfosForm.controls.validationDate.value, 'dd-MM-yyyy'),
                    socialClientCategory:
                    this.ocrInfosForm.controls.socialClientCategory.value,
                    gender: this.ocrInfosForm.controls.gender.value ? this.ocrInfosForm.controls.gender.value : 'None',
                    maidenName: this.ocrInfosForm.controls.maidenName.value,
                    maidenNameInArabic: this.ocrInfosForm.controls.maidenNameInArabic.value,
                    firstNameFatherInArabic: this.ocrInfosForm.controls.firstNameFatherInArabic.value,
                    lastNameMotherInArabic: this.ocrInfosForm.controls.lastNameMotherInArabic.value,
                    firstNameMotherInArabic: this.ocrInfosForm.controls.firstNameMotherInArabic.value,
                },
                professionalSituation: {
                    personnelSituationAddress: this.ocrInfosForm.controls.address.value,
                    activityDomain: this.ocrInfosForm.controls.activityDomain.value ? this.activityTranslatePipe.transform(this.ocrInfosForm.controls.activityDomain.value) : '',
                    activityDomaineBank: this.ocrInfosForm.controls.activityDomainTwo.value ? this.activityTranslatePipe.transform(this.ocrInfosForm.controls.activityDomainTwo.value) : '',
                    professionalClientCategory:
                    this.ocrInfosForm.controls.professionalClientCategory.value,
                    contractType: this.ocrInfosForm.controls.contractType.value,
                    employer: this.ocrInfosForm.controls.employer.value,
                    employerAddress: this.ocrInfosForm.controls.employerAddress.value,
                    employerWilaya: this.ocrInfosForm.controls.employerWilaya.value,
                    profession: this.professionTranslatePipe.transform(this.ocrInfosForm.controls.profession.value),
                    professionalPhone: this.ocrInfosForm.controls.professionalPhone.value,
                    // recruitmentDate: this.ocrInfosForm.controls.recruitmentDate.value,
                    recruitmentDate: this.datePipe.transform(this.ocrInfosForm.controls.recruitmentDate.value, 'dd-MM-yyyy'),
                    netSalaryRENA: this.ocrInfosForm.controls.netSalaryRENA.value ? this.ocrInfosForm.controls.netSalaryRENA.value.replace(' ', '') : '',
                    salary: this.ocrInfosForm.controls.montant_net.value ? this.ocrInfosForm.controls.montant_net.value.replace(' ', '') : '',
                    retraitDate: this.ocrInfosForm.controls.professionalClientCategory.value !== 'MILITARY_RETIRED' ? this.datePipe.transform(this.ocrInfosForm.controls.dateRetraite.value, 'dd-MM-yyyy') : '',
                    date_retraite: '',
                    date_recrutement: '',
                    radiationRecruitmentDate: this.ocrInfosForm.controls.professionalClientCategory.value === 'MILITARY_RETIRED' ? this.datePipe.transform(this.ocrInfosForm.controls.dateRetraite.value, 'dd-MM-yyyy') : '',
                    date_radiation: this.ocrInfosForm.controls.professionalClientCategory.value === 'MILITARY_RETIRED' ? this.datePipe.transform(this.ocrInfosForm.controls.dateRetraite.value, 'dd-MM-yyyy') : '',
                },
                financialSituation: {
                    accountKey: this.ocrInfosForm.controls.accountKey.value,
                    accountNumber: this.ocrInfosForm.controls.accountNumber.value,
                    accountNumberCCP: this.ocrInfosForm.controls.accountNumberCCP.value,
                    accountNumberBank: this.ocrInfosForm.controls.accountNumberBank.value ? this.ocrInfosForm.controls.accountNumberBank.value : '',
                    accountType: this.ocrInfosForm.controls.accountType.value,
                    mortgageAmount: this.ocrInfosForm.controls.mortgageAmount.value ? this.ocrInfosForm.controls.mortgageAmount.value.replace(' ', '') : '',
                    mortgageEndDate: this.datePipe.transform(this.ocrInfosForm.controls.mortgageEndDate.value, 'dd-MM-yyyy'),
                    // mortgageEndDate: this.ocrInfosForm.controls.mortgageEndDate.value,
                    mortgageLenderBank: this.translateLenderBank(this.ocrInfosForm.controls.mortgageLenderBank.value),
                    carLoanAmount: this.ocrInfosForm.controls.carLoanAmount.value ? this.ocrInfosForm.controls.carLoanAmount.value.replace(' ', '') : '',
                    carCreditEndDate: this.datePipe.transform(this.ocrInfosForm.controls.carCreditEndDate.value, 'dd-MM-yyyy'),
                    // carCreditEndDate: this.ocrInfosForm.controls.carCreditEndDate.value,
                    carLoanLenderBank: this.translateLenderBank(this.ocrInfosForm.controls.carLoanLenderBank.value),
                    otherCreditAmount: this.ocrInfosForm.controls.otherCreditAmount.value ? this.ocrInfosForm.controls.otherCreditAmount.value.replace(' ', '') : '',
                    otherCredit: this.ocrInfosForm.controls.otherCredit.value != null ? this.ocrInfosForm.controls.otherCredit.value : '',
                    rentCredit: this.ocrInfosForm.controls.rentCredit.value != null ? this.ocrInfosForm.controls.rentCredit.value : '',
                    rentCreditAmount: this.ocrInfosForm.controls.rentCreditAmount.value ? this.ocrInfosForm.controls.rentCreditAmount.value.replace(' ', '') : '',
                    savingsAccount: this.ocrInfosForm.controls.savingsAccount.value != null ? this.ocrInfosForm.controls.savingsAccount.value : '',
                    savingsAccountAmount: this.ocrInfosForm.controls.savingsAccountAmount.value ? this.ocrInfosForm.controls.savingsAccountAmount.value.replace(' ', '') : '',
                    otherCreditEndDate: this.datePipe.transform(this.ocrInfosForm.controls.otherCreditEndDate.value, 'dd-MM-yyyy'),
                    bankLenderCreditOther: this.translateLenderBank(this.ocrInfosForm.controls.bankLenderCreditOther.value),
                    otherIncome: this.ocrInfosForm.controls.otherIncome.value,
                },
                informationAddress: {
                    postNameAscii: '',
                    postAddressAscii: '',
                    communeNameAscii: '',
                    dairaNameAscii: '',
                    wilayaNameAscii: '',
                    wilayaNameAsciiInArabic: this.ocrInfosForm.controls.wilayaNameAsciiInArabic.value,
                    communeNameAsciiInArabic: this.ocrInfosForm.controls.communeNameAsciiInArabic.value,
                },
            };

            this.clientOcrInformationService.updateClientInfos(clientInfoUpdated).subscribe({
                next: (res) => {
                    // update ocr information on step 3
                    this.ocrInfos = res;
                    this.documentService.ocrSubject.next(res);
                    this.foldersService.clientInfoUpdated$.next(res.isInfoCompleted);

                    const message = `client information successfully has been updated.`;
                    this.layoutUtilsService.showActionNotification(
                        message,
                        MessageType.Update,
                        5000,
                        true,
                        true
                    );
                    this.step = 4;
                    this.router.navigate([], {queryParams: {step: 4}});
                },
                error: (error) => {
                    const message = `error in update client informations ! try again`;
                    this.layoutUtilsService.showActionNotification(
                        message,
                        MessageType.Update,
                        5000,
                        true,
                        true
                    );
                },
            });
        } else {
            this.step = 4;
            this.router.navigate([], {queryParams: {step: 4}});
        }
    }

    translateLenderBank(name: string) {
        if (name === '') {
            return 'NONE';
        }
        return BANK_LENDER.find(option => option.name === name).value;
    }

    commentFolder() {
        this.router.navigateByUrl('ek-ecommerce/folder/edit/' + this.idOrder + '/' + this.idFolder + '/comments');
    }

    sentEmailToClient() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.height = '400px';
        dialogConfig.width = '400px';

        const dialogRef = this.dialog.open(EkClientEmailComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((data) => {
            if (data.description && this.client.id && data.subject) {
                this.customersService
                    .sendMail(this.client.id, data.description, data.file, data.subject)
                    .subscribe((res) => {
                        if (res) {
                            this.layoutUtilsService.showActionNotification(
                                'Email envoyé avec succès!',
                                MessageType.Update,
                                10000,
                                true,
                                true
                            );
                        } else {
                            this.layoutUtilsService.showActionNotification(
                                'Erreur dans l\'envoie de l\'email',
                                MessageType.Update,
                                10000,
                                true,
                                true
                            );
                        }
                    });
            }
        });
    }

    getShippingAddress() {
        this.foldersService.getShippingAddressFolder(this.idFolder).subscribe(res => {
            if (res.body) {
                this.foldersService.shippingSbj$.next(res.body);
            }
        });
    }

    // For Debugging Forms
    getFormValidationErrors(form: FormGroup) {
        Object.keys(form.controls).forEach(key => {
            const controlErrors: ValidationErrors = form.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });
    }


    setAllValuesToTrue() {
        this.oneByOneValidations = {
            ficheFamilliale: true,
            extraitDeNaissance: true,
            residence: true,
            chifa: true,
            FichePaie1: true,
            FichePaie2: true,
            FichePaie3: true,
            cheque: true,
            cni: true,
            cni_verso: true,
            ccp: true,
            attestationDeTravail: true,
            retraite: true,
            faces: true,
            rena: true,
            radiation: true,
            releveDeCompte: true,
            incomeMilitary: true
        };
    }

    ngOnDestroy(): void {
        this.Subs.forEach((sub) => {
            sub.unsubscribe();
        });
        this.setAllValuesToTrue();
        this.folderVerificationService.documents$.next(null);
        this.folderVerificationService.ocrMessages$.next(null);
        this.foldersService.bankFeedback$.next('');
        this.foldersService.folderStateSubj$.next(null as any);
        this.foldersService.clientInfoUpdated$.next(null as any);
        this.orderSplitService.selectedOrderSubject.next(null as any);
        this.foldersService.folderSbj$.next(null as any)
        this._myData$.next(null as any);

    }

    displayFiles() {
        // this.loadingFilesSubject.next(true);
        const dialogRef = this.dialog.open(DownloadFileDialoguComponent, {
                width: '51rem',
                height: '45rem',
                data: {
                    idFolder: this.idFolder,
                    requestNumber: this.folder.requestNumber,
                    client: this.client.firstname + ' ' + this.client.lastname,
                }
            }
        );

    }

    canManageComments(folder:FolderModel)
    {

        if (this.currentRole === 'ROLE_BANKER_EK' && !BANKER_STATES.includes(folder.folderState)) {
            return false;
        }
        return true;
    }

    checkPhoneNumber(phone: string) {

        if (phone) {

            const dialogRef = this.dialog.open(PhoneNumberValidationPopupComponent,
                {
                    data: {
                        phone: phone
                    },
                    width: '30rem'
                });

            dialogRef.afterClosed().subscribe((code: string) => {
                if (code) {
                    this.createNewOrderSplit();
                }
            });
        } else {
            return;
        }

    }

    createNewOrderSplit(){

        this.loadingSubject$.next(true);

        this.store.dispatch(
            OrderSplitCreated({OrderSplit: this.orderCreationEntity})
        );


        // order creation failed
        this._actions$
            .pipe(ofType(OrderSplitCreationFailed))
            .subscribe((data: any) => {

                if (data && data.error.status === 400) {
                    const message = 'La quantité n\'est pas disponible !';
                    this.layoutUtilsService.showActionNotification(
                        message,
                        MessageType.Update,
                        5000,
                        true,
                        true
                    );
                } else {

                    if (data.error.status === 500) {
                        const message = data.error.error.message;
                        this.layoutUtilsService.showActionNotification(
                            message,
                            MessageType.Update,
                            5000,
                            true,
                            true
                        );
                    } else {
                        const message = `il y a une erreur dans la création du dossier !`;
                        this.layoutUtilsService.showActionNotification(
                            message,
                            MessageType.Update,
                            5000,
                            true,
                            true
                        );
                    }

                }

                // stop spinner
                this.loadingSubject$.next(false);

            });

        // order updated successfully

        this._actions$
            .pipe(ofType(OrderSplitCreatedSuccessfully))
            .subscribe((data: any) => {
                this.store
                    .pipe(select(selectLastCreatedOrderSplitId))
                    .subscribe((newId) => {
                        if (!newId) {
                            return;
                        }

                        this.updateClient();

                        const message = `New order successfully has been added.`;
                        this.layoutUtilsService.showActionNotification(
                            message,
                            MessageType.Create,
                            5000,
                            true,
                            true
                        );

                        this.orderSplitService
                            .getOrderSplitById(newId)
                            .subscribe((order) => {
                                this.selectedOrderSubject.next(order);
                                this.orderSplitService.selectedOrderSubject.next(order);
                                this.loadingSubject$.next(false);
                                this.setSocialInfo(
                                    order.folderId,
                                    'OTHER',
                                    this.simulationForm.controls.profSit.value,
                                    this.simulationForm.controls.persoSit.value
                                );
                                this.router.navigateByUrl(
                                    '/ek-ecommerce/folder/edit/' +
                                    newId +
                                    '/' +
                                    order.folderId +
                                    '?step=2'
                                );
                            });
                    });
            });
    }
}
